const ProgressBar = ({ percent }) => {
    return (
        <div className="relative pt-2">
            <div className="overflow-hidden h-2 text-xs flex rounded-lg bg-secondary-100 h-3.5">
                <div
                    className="flex flex-col text-center whitespace-nowrap text-basicWhite justify-center rounded-lg bg-secondary-500"
                    style={{
                        width: `${percent}%`,
                    }}
                ></div>
            </div>
        </div>
    );
};

export default ProgressBar;
