import { CheckIcon, SearchIcon } from '@heroicons/react/solid';
import useMultiSelectSearch from 'hooks/useMultiSelectSearch';
import { XIcon } from '@heroicons/react/outline';
import { assetClassOptions } from '@helpers/constants';
import FilterHead from './FilterHead';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Image from './Image';

const MultiSelectDropdown = ({
    // required props
    name,
    label,
    options,
    // optional props
    defaultOpen = true,
    defaultSelected = [],
    className = '',
    labelClassName = '',
    inputWrapperClass = '',
    canReset = false,
    canCollapsible = false,
    isDebounseSearch = false,
    onSelect = (val) => { },
    onSearchDebounsed = () => { },
}) => {
    const {
        ref,
        isComponentVisible,
        selected,
        dropdownOptions,
        isLoading,
        isVisible,
        value,
        setIsVisible,
        onInputChange,
        onReset,
        isActive,
        onChange,
        setIsComponentVisible,
        onRemoveSelectedItem,
    } = useMultiSelectSearch({
        defaultSelected,
        options,
        canCollapsible,
        isDebounseSearch,
        defaultOpen,
        onSelect,
        onSearchDebounsed,
    });

    return (
        <div className={className}>
            <div className="flex flex-col w-auto font-montserrat">
                <FilterHead
                    label={label}
                    isVisible={isVisible}
                    labelClassName={labelClassName}
                    canReset={false}
                    canCollapsible={canCollapsible}
                    onReset={onReset}
                    setIsVisible={setIsVisible}
                />
                <div
                    ref={ref}
                    className={`relative  ${isVisible ? 'flex' : 'hidden'
                        } flex-col gap-2 w-auto ${inputWrapperClass}`}
                >
                    <div className="flex items-center border border-gray-300 rounded-md bg-basicWhite">
                        <input
                            type="text"
                            placeholder={
                                options?.length > 0 ? 'Select' : 'Search here'
                            }
                            onClick={() =>
                                setIsComponentVisible(!isComponentVisible)
                            }
                            value={value}
                            onChange={onInputChange}
                            className="w-full focus:placeholder-opacity-0 placeholder-gray-500 h-[30px] px-3 py-2 text-xs font-normal text-gray-500 bg-basicWhite rounded-md border-y border-l border-none outline-none border-gray-200 focus:outline-none focus:border-none active:border-none focus:ring-gray-200 focus:ring-opacity-0"
                        />
                        {/* Search Icon */}
                        <Image
                            src="/images/Search.svg"
                            className="w-3 h-3 mx-2 bg-basicWhite cursor-pointer text-gray-500"
                            onClick={() => {
                                setIsComponentVisible(true);
                            }}
                        />
                    </div>
                    {/* hidden input */}
                    <input
                        type="hidden"
                        name={name}
                        value={selected.map((item) => item).join(',')}
                    />

                    <div
                        className={`${isComponentVisible ? 'block' : 'hidden'
                            } origin-top-right relative right-0 mt-0 w-full rounded-md bg-basicWhite ring-1 ring-basicBlack ring-opacity-5 focus:outline-none z-10`}
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        <div
                            className="p-1 flex flex-col gap-2 overflow-auto"
                            role="none"
                        >
                            {isLoading && (
                                <div className="flex items-center justify-center h-14">
                                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900"></div>
                                </div>
                            )}
                            {
                                // if no data found
                                dropdownOptions.length === 0 && !isLoading && (
                                    <div className="flex items-center justify-center h-10">
                                        <div className="text-gray-500 text-sm">
                                            {options?.length
                                                ? 'No options'
                                                : 'type to see the results'}
                                        </div>
                                    </div>
                                )
                            }
                            {dropdownOptions.map((option, i) => {
                                return (
                                    <button
                                        key={i}
                                        onClick={(e) => onChange(e, option)}
                                        className={`flex items-center justify-between w-full text-left px-4 py-2 rounded-md text-xs text-gray-700 ${isActive(option.value)
                                            ? ' bg-primary-50 '
                                            : ' hover:bg-gray-100 hover:text-gray-900'
                                            }}`}
                                        role="menuitem"
                                    >
                                        {option.label}
                                        {isActive(option.value) ? (
                                            <CheckIcon className="w-4 h-4" />
                                        ) : null}
                                    </button>
                                );
                            })}
                        </div>
                    </div>

                    {/* Selected options */}
                    {selected?.length > 0 ? (
                        <div className="flex flex-wrap gap-2">
                            {selected.map((item, i) => {
                                return (
                                    <div
                                        className="flex items-center gap-2 px-2 py-2 bg-gray-100 rounded-md text-xs"
                                        key={i}
                                    >
                                        {name === 'type' ? (
                                            <div className="text-gray-900">
                                                {assetClassOptions[item]}
                                            </div>
                                        ) : (
                                            <div className="text-gray-900">
                                                {item}
                                            </div>
                                        )}
                                        <button
                                            onClick={(e) =>
                                                onRemoveSelectedItem(e, i)
                                            }
                                            className="text-gray-500"
                                        >
                                            <XIcon className="w-3 h-3 cursor-pointer border border-gray-900 rounded-sm font-bold text-gray-900" />
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default MultiSelectDropdown;
