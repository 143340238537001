import { Text, Image } from '@atoms/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { allowedFileUploads, defaultUploadedPdfIcon } from '@helpers/constants';

const UploadedImageCard = ({
    label,
    imageSrc,
    isSingleUpload,
    uploadFile,
    uploadedImageType,
}) => {
    const uploadedImage =
        uploadedImageType === 'application/pdf'
            ? defaultUploadedPdfIcon
            : imageSrc;
    return (
        <div
            className={`flex ${isSingleUpload && 'flex-col items-center'
                } w-full p-2 rounded-lg`}
        >
            {imageSrc ? (
                <div className="w-full bg-gray-700 flex justify-center p-5 rounded-lg">
                    <img
                        src={uploadedImage}
                        className="rounded-lg object-none"
                        style={{ height: '180px', width: '200px' }}
                    />
                </div>
            ) : (
                <FontAwesomeIcon
                    icon="file-pdf"
                    className="rounded-lg"
                    style={{ height: '48px', width: '48px' }}
                />
            )}
            <div className="flex flex-col items-center justify-center w-full px-2 py-3">
                <Text content={label} className="text-yellow-500 mb-3" />
                <label
                    htmlFor={'fileUpload' + imageSrc}
                    className="cursor-pointer"
                >
                    <div className="flex">
                        <Image src="/images/investors/kyc/re-upload-icon.svg" />
                        <Text
                            className="ml-3 text-primary-400"
                            content="Reupload"
                        />
                    </div>
                </label>
                <input
                    type="file"
                    accept={allowedFileUploads}
                    id={'fileUpload' + imageSrc}
                    name="cameraInput"
                    onChange={(event) => uploadFile(event)}
                    className="hidden btn btn-default spacer-top"
                />
            </div>
        </div>
    );
};

export default UploadedImageCard;
