import { Fragment } from 'react';
import { Link } from '@atoms/index';
const Breadcrumbs = ({
    children,
    separator = '>',
    className = 'flex py-4 md:pr-6 xl:pr-3 2xl:pr-0 pr-3 rounded list-reset bg-grey-light text-grey',
}) => {
    const BreadCrumbLink = ({ href = '', label, active }) => {
        return (
            <li className="md:pr-2 pr-1">
                <Link
                    href={href}
                    className={`text-xs line-clamp-1 md:line-clamp-none lg:text-sm md:text-sm no-underline ${active ? 'text-gray-900 cursor-text' : 'text-gray-500'
                        }`}
                    children={label}
                />
            </li>
        );
    };

    return (
        <nav className="">
            <ol className={className}>
                {children.map((element, index) => {
                    return (
                        <Fragment key={index}>
                            <BreadCrumbLink
                                href={element.props.href}
                                label={element.props.label}
                                active={element.props.active}
                            />
                            {(() => {
                                if (index < children.length - 1) {
                                    return (
                                        <BreadCrumbLink
                                            className="text-gray"
                                            label={separator}
                                        />
                                    );
                                }
                            })()}
                        </Fragment>
                    );
                })}
            </ol>
        </nav>
    );
};
export default Breadcrumbs;
