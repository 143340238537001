import Text from '@atoms/Text';
import Image from '@atoms/Image';
import AccordionListItem from './AccordionListItem';
import useWindowDimensions from 'hooks/useWindowDimensions';

const AccordionItem = ({ item, isOpen, setSelectedIndex, index, length }) => {
    const { width } = useWindowDimensions();
    const renderIcon = () => (
        <div
            className={`flex items-center justify-center transition-all duration-150 ease-linear rounded-full opacity-60 w-6 h-6  p-1 lg:w-9 lg:h-9 lg:p-2  ${!isOpen ? 'bg-primary-50' : ''
                } `}
        >
            {!isOpen ? (
                <Image
                    className="transition-all duration-200 ease-linear text-primary-500 "
                    src={'./images/newDashboard/arrow-down-black.svg'}
                    alt={'arrow_down'}
                />
            ) : (
                <Image
                    className="text-base font-bold transition-all duration-200 ease-linear transform rotate-180 "
                    src={'./images/newDashboard/arrow-down-black.svg'}
                    alt={'arrow_down'}
                />
            )}
        </div>
    );
    return (
        <div
            className={
                'flex flex-col   transition-all ease-in duration-200 px-4 sm:py-4 md:px-6  py-2  md:py-1 ' +
                (isOpen
                    ? ' bg-basicWhite/80 pt-4  flex-grow md:py-3 '
                    : 'bg-primary-50 justify-center shrink  md:!my-3 ') +
                (isOpen && 0 > index < length ? '   ' : ' mt-0 rounded-3xl')
            }
        >
            <div
                className="flex items-center justify-between space-x-4 cursor-pointer lg:space-x-5"
                onClick={() => setSelectedIndex(index)}
            >
                <div className="flex flex-col transition-all duration-150 ease-linear">
                    <Text
                        content={item.name}
                        htmlTag={'h1'}
                        className={
                            'font-semibold text-sm md:text-base  ' +
                            (!isOpen
                                ? 'text-primary-500 opacity-100'
                                : 'text-gray-900 opacity-90 ')
                        }
                    />

                    {!isOpen && width > 768 && (
                        <Text
                            content={item.desc}
                            htmlTag={'h1'}
                            className={
                                '  font-normal text-sm ' +
                                (isOpen
                                    ? 'text-gray-800 '
                                    : ' text-primary-500')
                            }
                        />
                    )}
                </div>
                {renderIcon()}
            </div>
            <ul
                className={
                    ' transition-all ease-in duration-400 flex flex-col flex-grow justify-between  overflow-hidden  ' +
                    (isOpen ? 'max-h-full  py-4  ' : ' max-h-0')
                }
            >
                {item &&
                    item.items.map((listItem, listIndex) => (
                        <AccordionListItem
                            listItem={listItem}
                            key={listIndex}
                        />
                    ))}
            </ul>
        </div>
    );
};

export default AccordionItem;
