import { Text, ToolTip } from '@atoms/index';

const Element = ({ label, value, marginClass = 'mt-4', tooltipContent }) => {
    return (
        <div className={`flex justify-between ${marginClass}`}>
            <div className="flex items-center">
                <Text content={label} className="pr-1 text-sm md:text-base" />
                {tooltipContent && <ToolTip content={tooltipContent} />}
            </div>
            <Text
                content={value}
                className="font-semibold md:text-base whitespace-nowrap"
            />
        </div>
    );
};

export default Element;
