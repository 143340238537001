import { useEffect } from 'react';

const useToggleBodyOverflow = (modalOpen) => {
    useEffect(() => {
        document.body.style.overflow = modalOpen ? 'hidden' : 'unset';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [modalOpen]);
};

export default useToggleBodyOverflow;
