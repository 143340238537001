import Link from 'next/link';

const NextLink = ({
    href,
    onClick,
    size,
    weight,
    className,
    target,
    id,
    children,
    gtmTag = ''
}) => {
    return (
        <Link href={href || '#'} passHref legacyBehavior>
            <a
                onClick={onClick}
                className={`text-${size} font-${weight} ${className}`}
                target={target}
                id={id}
                gtmtag={gtmTag}
            >
                {children}
            </a>
        </Link>
    );
};

export default NextLink;
