import React, { useState } from 'react';
import { Text, Modal, Image } from '@atoms/index';
import { getNumberWithComma } from '@helpers/miscelleanous';
import PaymentScheduleDesktopView from './PaymentScheduleDesktopView';
import PaymentScheduleMobileView from './PaymentScheduleMobileView';
import {
    getTotalReturnReceived,
    payScheduleDateWithInterestObj,
    showDetailsStatusArrow,
    showDetailsStatusMessage,
    totalAmount,
} from './lib/helper';
import texts from './en.json';

const PaymentSchedule = ({
    show,
    onClose,
    returns,
    paySchedule,
    units,
    unitPrice,
    viewInvestments = false,
    id,
}) => {
    const [selectedPayment, setSelectedPayment] = useState({});
    const [showDetails, setShowDetails] = useState(false);

    return (
        <Modal
            show={show}
            onClose={onClose}
            width="md:w-2/3 lg:1/2 md:max-w-screen-lg max-w-[95%]"
            height="h-122 !p-0"
            label={texts.RepaymentScheduleLabel}
            className="thin-scrollbar"
            id={id}
            children={
                <>
                    <div className="grid grid-rows-2 m-4 gap-2 md:grid-rows-1 md:grid-cols-2">
                        <div className="px-4 pt-4 pb-0 border rounded-lg border-gray-200 2xl:px-6">
                            <div className="flex w-full">
                                <Image
                                    src={texts.CubeBoxImagePath}
                                    className="w-8"
                                />
                                <div className="flex w-full items-center lg:items-start justify-between lg:flex-col pl-3 2xl:pl-5">
                                    <Text
                                        content={texts.NumberOfUnits}
                                        className="text-gray-500 p5-medium lg:p4-medium"
                                    />
                                    <Text
                                        content={`${units}`}
                                        className="text-basicBlack p4-semibold lg:p3-bold"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="px-4 py-4 border rounded-lg border-gray-200 2xl:px-6">
                            <div className="flex">
                                <Image
                                    src={texts.WalletImagePath}
                                    className="w-9"
                                />
                                <div className="flex w-full items-center lg:items-start justify-between flex-wrap lg:flex-nowrap lg:flex-col pl-3 2xl:pl-5">
                                    <Text
                                        content={texts.InvestedAmount}
                                        className="text-gray-500 p5-medium lg:p4-medium"
                                    />
                                    <Text
                                        content={`₹ ${getNumberWithComma(
                                            Number(unitPrice)
                                        )}`}
                                        className="text-basicBlack p4-semibold lg:p3-bold"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* repayment schedule table for desktop view  */}
                    <PaymentScheduleDesktopView
                        viewInvestments={viewInvestments}
                        totalAmount={totalAmount(returns)}
                        paySchedule={paySchedule}
                        returns={returns}
                        payScheduleDateWithInterestObj={
                            payScheduleDateWithInterestObj
                        }
                        units={units}
                        selectedPayment={selectedPayment}
                        setSelectedPayment={setSelectedPayment}
                        getTotalReturnReceived={getTotalReturnReceived}
                        setShowDetails={setShowDetails}
                        showDetails={showDetails}
                        showDetailsStatusMessage={showDetailsStatusMessage}
                        showDetailsStatusArrow={showDetailsStatusArrow}
                    />

                    {/* repayment schedule table for mobile view  */}
                    <PaymentScheduleMobileView
                        viewInvestments={viewInvestments}
                        paySchedule={paySchedule}
                        returns={returns}
                        payScheduleDateWithInterestObj={
                            payScheduleDateWithInterestObj
                        }
                        units={units}
                        totalAmount={totalAmount(returns)}
                        selectedPayment={selectedPayment}
                        setSelectedPayment={setSelectedPayment}
                        getTotalReturnReceived={getTotalReturnReceived}
                        setShowDetails={setShowDetails}
                        showDetails={showDetails}
                        showDetailsStatusMessage={showDetailsStatusMessage}
                        showDetailsStatusArrow={showDetailsStatusArrow}
                    />
                </>
            }
        />
    );
};

export default PaymentSchedule;
