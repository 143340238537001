import Image from 'next/legacy/image';
const LazyImage = ({
    loading,
    priority,
    imgClassName = 'w-full h-full',
    ...props
}) => {
    return (
        <div className={props.className}>
            <Image
                loading={loading || 'lazy'}
                layout="fill"
                className={imgClassName}
                src={props.src}
                alt={props.alt}
                onClick={props.onClick}
                placeholder={props?.placeholder || ''}
                blurDataURL={props?.blurDataURL || ''}
                priority={priority ? priority : false}
            />
        </div>
    );
};
export default LazyImage;
