import React from 'react';
import { Image, Text } from '@ui/atoms';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { UserIcon } from '@heroicons/react/solid';

const AvatarSection = ({ avatar, name, kycStatus, showNudgeForNewStepsAdded }) => {
    const router = useRouter();

    const getShowKycStatus = (status) => {
        const showKycStatus = {
            Pending: { show: 'pending', icon: '/images/pending-action.svg', className: 'bg-gray-200 text-gray-500' },
            Verified: { show: 'verified', icon: '/images/checked-filled.svg', className: 'bg-blue-brand text-basicWhite'  },
            ManualVerificationRequired: { show: 'in verification', icon: '/images/inprogress-timer.svg', className: 'bg-gray-200 text-gray-500' },
            KycUpdateRequired: { show: 'update required', icon: '/images/pending-action.svg', className: 'bg-gray-200 text-gray-500' },
        };

        return showNudgeForNewStepsAdded ? showKycStatus['KycUpdateRequired'] : (showKycStatus[status] || showKycStatus['Pending']);
    };

    const showKycStatus = getShowKycStatus(kycStatus);

    return (
        <div className="bg-basicWhite p-3 flex items-center justify-between rounded-xl border-2 border-secondary-200 cursor-pointer" onClick={() => router.push('/profile')}>
            <div className="flex justify-center items-center gap-2">
                <div className="w-14 h-14 rounded-full bg-secondary-200 flex justify-center items-center text-secondary-700 p1-semibold">
                    {avatar || <UserIcon className='w-6 h-6 text-secondary-700 p1-semibold'/>}
                </div>
                <div className="flex flex-col gap-2">
                    {name ? <Text
                        content={name}
                        className="p4-medium text-primary-500"
                    /> : null}
                    <div className={`${showKycStatus?.className} p6-medium px-2 py-1 rounded-2xl self-start flex justify-center items-center gap-1`}>
                        <Image src={showKycStatus?.icon} alt='status' className='w-4 h-4'/>
                        KYC {showKycStatus?.show}
                    </div>
                </div>
            </div>
            <button>
                <ChevronRightIcon className="w-6 h-6 text-primary-500" />
            </button>
        </div>
    );
};

export default AvatarSection;
