import * as CsrHttpService from '@http/csr-http.service';
import * as SsrHttpService from '@http/ssr-http.service';
import { hasWindow } from '@services/http.service';
import {
    CREATE_AND_GET_APPENDED_SIGNED_TRANSACTION_DOCUMENT_URL,
    CREATE_APPENDED_SIGNED_TRANSACTION_DOCUMENT_URL,
    CREATE_INVESTOR_OPPORTUNITY_DOCUMENT_URL,
    GET_INVESTOR_OPPORTUNITY_DOCUMENT,
    GET_INVESTOR_OPPORTUNITY_DOCUMENT_BY_ENTITY_OPPORTYNITY_ID_URL,
} from '@services/urls/investorOpportunityDocument';
import {
    GENERATE_TOKEN_URL,
    GET_ALL_SIGNED_DOCUMENTS_FOR_CONFIRMED_PAYMENTS_URL,
    GET_PRESIGNED_DOCUMENT_URL,
    GET_SIGNED_DOCUMENTS_URL,
    REQUEST_DIGITAL_SIGN_URL,
} from '@services/urls/opportunityDocuments';

export const createInvestorOpportunityDocument = (data, ctx = null) => {
    const url = CREATE_INVESTOR_OPPORTUNITY_DOCUMENT_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getSignedDocuments = (
    opportunityId,
    page,
    limit,
    paymentStatus,
    type = 'SignedTransactionDocument',
    ctx = null
) => {
    const url = GET_SIGNED_DOCUMENTS_URL(
        opportunityId,
        page,
        limit,
        type,
        paymentStatus
    );
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getPresignedUrl = (id, ctx) => {
    const url = GET_PRESIGNED_DOCUMENT_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorOpportunityDocumentByInvestmentId = (
    entityId,
    opportunityId,
    investmentId,
    type,
    ctx = null
) => {
    const url = GET_INVESTOR_OPPORTUNITY_DOCUMENT_BY_ENTITY_OPPORTYNITY_ID_URL(
        entityId,
        opportunityId,
        investmentId,
        type
    );
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorOpportunityDocument = (
    entityId,
    opportunityId,
    type,
    ctx = null
) => {
    const url = GET_INVESTOR_OPPORTUNITY_DOCUMENT(
        entityId,
        opportunityId,
        type
    );
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const generateToken = (data, ctx = null) => {
    const url = GENERATE_TOKEN_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const requestDigitalSign = (data, ctx = null) => {
    const url = REQUEST_DIGITAL_SIGN_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getAllSignedDocumentsForConfiredPaymentsUrl = (
    opportunityId,
    ctx = null
) => {
    const url =
        GET_ALL_SIGNED_DOCUMENTS_FOR_CONFIRMED_PAYMENTS_URL(opportunityId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createAppendedSignedTransactionDocument = (data, ctx = null) => {
    const url = CREATE_APPENDED_SIGNED_TRANSACTION_DOCUMENT_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const createAndGetAppendedSignedTransactionDocument = (
    data,
    ctx = null
) => {
    const url = CREATE_AND_GET_APPENDED_SIGNED_TRANSACTION_DOCUMENT_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};
