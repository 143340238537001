import { useEffect, useRef } from 'react';

/**
 * useEventListener
 * @param {string} eventName - Name of the event to listen to
 * @param {function} handler - Event handler function
 * @param {Element|null} [element=null] - Element to attach the event listener to
 */
function useEventListener(eventName, handler, element = null) {
    // By default, we're setting element to null rather than window.

    const savedHandler = useRef();

    // Update the handler ref on each render
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        // If no element is provided, try to use window, but if it's not available, just return.
        const targetElement = element
            ? element
            : typeof window !== 'undefined'
            ? window
            : null;

        // Ensure the element supports addEventListener
        const isSupported = targetElement && targetElement.addEventListener;
        if (!isSupported) return;

        // Create the event listener
        const eventListener = (event) => savedHandler.current(event);
        targetElement.addEventListener(eventName, eventListener);

        // Clean up the event listener on component unmount
        return () => {
            targetElement.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);
}

export default useEventListener;
