import useMultiCheckboxOrRadioBox from 'hooks/useMultiCheckboxOrRadioBox';
import FilterHead from './FilterHead';

const RadioBox = ({
    name,
    label,
    options,
    className,
    // optional
    labelClassName = '',
    inputWrapperClass = '',
    canReset = false,
    canCollapsible = false,
    defaultSelected = [],
    onSelect = (val) => { },
}) => {
    const { selected, isVisible, setIsVisible, onChange, onReset } =
        useMultiCheckboxOrRadioBox({
            type: 'radio',
            options,
            defaultSelected,
            canCollapsible,
            onSelect,
        });

    return (
        <div className={className}>
            <div className="flex flex-col gap-2 w-auto h-30 font-montserrat">
                <FilterHead
                    label={label}
                    isVisible={isVisible}
                    labelClassName={labelClassName}
                    canCollapsible={canCollapsible}
                    canReset={false}
                    onReset={onReset}
                    setIsVisible={setIsVisible}
                />
                <div
                    className={`relative ${isVisible ? 'flex' : 'hidden'
                        } flex-row gap-2 w-auto flex-wrap justify-center xl:justify-around mb-4 mt-2`}
                >
                    {options.map((option, i) => {
                        return (
                            <div
                                className={`flex items-center gap-2 px-2 py-1 rounded-md font-montserrat ${inputWrapperClass}`}
                                key={i}
                            >
                                <input
                                    className="w-4 h-4 text-primary-500 bg-gray-100 border-gray-300 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    type="radio"
                                    name={name}
                                    value={option.value}
                                    id={option.label}
                                    checked={
                                        selected.join(',') === option.value
                                    }
                                    onChange={(e) => onChange(e, option)}
                                />
                                <label
                                    htmlFor={option.label}
                                    className="cursor-pointer text-gray-900 text-xs font-medium"
                                >
                                    {option.label}
                                </label>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default RadioBox;
