import { UrlParamsReplace } from './index';

export const CREATE_INVESTOR_OPPORTUNITY_DOCUMENT_URL = () =>
    UrlParamsReplace('/investor-opportunity-documents');

export const GET_SIGNED_DOCUMENTS_URL = (
    opportunityId,
    page,
    limit,
    type,
    paymentStatus
) =>
    UrlParamsReplace(
        '/opportunities/:opportunityId/investor-opportunity-documents?page=:page&limit=:limit&type=:type&paymentStatus=:paymentStatus',
        { opportunityId, page, limit, type, paymentStatus }
    );

export const GET_PRESIGNED_DOCUMENT_URL = (id) =>
    UrlParamsReplace('/investor-opportunity-documents/:id/get-presigned-url', {
        id,
    });

export const GET_TRANSACTION_DOCUMENT_BY_INVESTOR_OPPORTYNITY_ID_URL = (
    investorId,
    opportunityId,
    investmentId,
    type
) =>
    UrlParamsReplace(
        '/investors/:investorId/investor-opportunity-documents?opportunityId=:opportunityId&investmentId=:investmentId&type=:type',
        { investorId, opportunityId, investmentId, type }
    );

export const GENERATE_TOKEN_URL = () =>
    UrlParamsReplace('/digio/generate_token');

export const REQUEST_DIGITAL_SIGN_URL = () =>
    UrlParamsReplace('/digio/request-sign');

export const GET_ALL_SIGNED_DOCUMENTS_FOR_CONFIRMED_PAYMENTS_URL = (
    opportunityId
) =>
    UrlParamsReplace(
        '/opportunities/:opportunityId/signed-investor-opportunity-documents',
        { opportunityId }
    );
export const CREATE_OPPORTUNITY_DOCUMENT = () =>
    UrlParamsReplace('/opportunity-documents');

export const DELETE_OPPORTUNITY_DOCUMENT = (id) =>
    UrlParamsReplace('/opportunity-documents/:id', {
        id,
    });

export const OPPORUNITY_DOCUMENT_ADD_TEMPLATE = () =>
    UrlParamsReplace('/opportunity-documents/add-template');

export const CREATE_OPPORTUNITY_PREFERENCE = (id) =>
    UrlParamsReplace(`/opportunities/${id}/preference`);
