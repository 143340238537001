import { Tag, Text } from '@atoms/index';

const Alert = ({ text, buttonLabel, onClick, className }) => {
    return (
        <div
            className={`flex items-center text-left xl:text-center text-basicBlack bg-pink-300-50 h-10 md:px-2 ${className}`}
        >
            <Text
                className="overflow-clip font-inter sm:text-sm md:text-sm lg:text-sm xl:text-sm lg:max-w-full "
                size="xs"
                content={text}
            />
            <Tag
                onClick={onClick}
                label={buttonLabel}
                className="p-1 px-2 my-2 ml-3 text-primary-500 border border-primary-500 whitespace-nowrap md:mx-5 xl:mx-2"
                size="xs"
                weight="semibold"
            />
        </div>
    );
};

export default Alert;
