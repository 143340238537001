import Moment from 'moment';
import { getPaymentStatus } from '@helpers/miscelleanous';

export const totalAmount = (returns) => returns
        .slice(1)
        .reduce((acc, item) => (item.cashFlow ? item.cashFlow + acc : acc), 0)
  .toFixed(2);
        
const getShowDetailsStatus = (payment) =>
  showDetails && selectedPayment === payment;

export const payScheduleDateWithInterestObj = (paySchedule) => {
        let dataObj = {};

        paySchedule?.map((payment) => {
            dataObj[Moment(payment.date).format('MM-DD-YYYY')] =
                payment.interest > 0 ? true : false;
        });

        return dataObj;
    }

export const getTotalReturnReceived = () =>
        returns.slice(1).reduce((acc, prev) => {
            if (getPaymentStatus(prev) === 'Received') {
                return acc + (prev.amount || Number(units) * prev.cashFlow);
            }
            return acc;
        }, 0);

export const showDetailsStatusMessage = (payment) =>
        getShowDetailsStatus(payment) ? 'Hide Details' : 'Show Details';

export const showDetailsStatusArrow = (payment) =>
        getShowDetailsStatus(payment)
            ? '/images/arrowTop.svg'
            : '/images/arrowRight.svg';