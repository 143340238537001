import { Card, Image, Text, Button } from '@atoms/index';
import { useState, useEffect } from 'react';
const Moment = require('moment');
import PaymentSchedule from '@components/Investor/Investment/PaymentSchedule';
import { getSignedUrl } from '@services/aws.service';
import {
    getConterPartyName,
    getConterPartyLogo,
    opportunityFullyRepaidStates,
} from '@helpers/constants';
import { getNumberWithComma, getTenure } from '@helpers/miscelleanous';
import DownloadRealisedIRRDetails from '@components/Investor/Investment/DownloadRealisedIRRDetails/index.js';
import { createAndGetAppendedSignedTransactionDocument } from '@services/investor-opportunity-documents.service';

const NewInvestmentSummeryVertical = ({ investment, ...property }) => {
    const bucket =
        process.env.NEXT_PUBLIC_INVESTOR_OPPORTUNITY_DOCUMENTS_BUCKET;
    const documentBucket = process.env.NEXT_PUBLIC_OPPORTUNITIY_DOCUMENT_BUCKET;
    const [leasingDocument, setLeasingDocument] = useState();
    const [tenure, setTenure] = useState();
    const opportunity = investment.Opportunity;

    const isFullyRepaid = opportunityFullyRepaidStates.includes(
        opportunity.state
    );
    const scheduledReturns = opportunity.returns.slice(1);

    const upcomingPayment = isFullyRepaid
        ? null
        : scheduledReturns.find((payment) => payment.status !== 'Received');

    const [showPaySchedule, setShowPaySchedule] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nextPayment] = useState(upcomingPayment);

    const nextRepaymentDate = (nextPayment) => {
        if (nextPayment?.date) {
            if (
                Moment(
                    nextPayment?.date,
                    'YYYY-MM-DDTHH:mm:ss.SSSZ',
                    true
                ).isValid()
            ) {
                return (
                    Moment(
                        nextPayment?.date,
                        'YYYY-MM-DDTHH:mm:ss.SSSZ'
                    ).format('DD MMM YYYY') || ''
                );
            } else {
                return (
                    Moment(nextPayment?.date, 'MM-DD-YYYY').format(
                        'DD MMM YYYY'
                    ) || ''
                );
            }
        }
        return '';
    };

    const downloadOrGenerateDocument = async () => {
        setLoading(true);
        let documentToDownload =
            opportunity.type === 'InvoiceDiscounting'
                ? investment.investorOpportunityDocuments.find(
                    (doc) => doc.type === 'AppendedSignedTransactionDocument'
                )
                : investment.investorOpportunityDocuments.find(
                    (doc) => doc.type === 'SignedTransactionDocument'
                );

        if (opportunity.type === 'InvoiceDiscounting' && !documentToDownload) {
            const appendedSignedTransactionDocument =
                await createAndGetAppendedSignedTransactionDocument({
                    signedTransactionDocumentId:
                        investment.investorOpportunityDocuments.find(
                            (doc) => doc.type === 'SignedTransactionDocument'
                        ).id,
                });

            await downloadAgreement(appendedSignedTransactionDocument.entity);
        } else {
            await downloadAgreement(documentToDownload);
        }
        setLoading(false);
    };

    const downloadAgreement = async (document) => {
        try {
            const response = await getSignedUrl(bucket, document?.name);
            if (response.status && response.entity)
                window.location.href = response.entity;
        } catch (e) {
            throw e;
        }
    };
    useEffect(() => {
        let filteredDocumnet = investment.Opportunity.documents.find(
            (item) => item.type === 'LeasingDocument'
        );
        setLeasingDocument(filteredDocumnet);
    }, []);
    useEffect(() => {
        if (opportunity.paymentSchedule.length > 0) {
            let lastPaymentSchudle = opportunity.paymentSchedule.slice(-1)[0];
            setTenure(
                getTenure(investment.investmentDate, lastPaymentSchudle.date)
            );
        } else setTenure(opportunity.tenure);
    }, []);

    const viewLeasingDocuemnt = async (document) => {
        setLoading(true);
        const response = await getSignedUrl(documentBucket, document.name);
        setLoading(false);
        if (response.status && response.entity)
            window.open(response.entity, '_blank');
    };

    const grossPayment = getNumberWithComma(
        Math.round(
            opportunity.returns
                .slice(1)
                .filter((p) => p.status == 'Received')
                .reduce(
                    (acc, pay) =>
                        acc +
                        (pay.amount ||
                            Number(investment.unitsPurchased) * pay.cashFlow),
                    0
                )
        )
    );

    const opportunityType = opportunity.type;

    const totalAmount = opportunity.dealSize;

    const investmentProportion =
        (investment.investedAmount * 100) / totalAmount / 100;

    const ablOpportunityGrossPayment = getNumberWithComma(
        Math.round(
            opportunity.returns
                .filter((p) => p.status == 'Received')
                .reduce(
                    (acc, pay) =>
                        acc +
                        (pay.amount ||
                            Number(investmentProportion) *
                            (pay.principal + pay.interest)),
                    0
                )
        )
    );

    const repaymentStatus =
        nextPayment?.status === 'Delayed' ? 'Delayed' : 'On Time';

    const status = nextPayment ? repaymentStatus : 'Fully Repaid';

    const repaymentStatusImg =
        status === 'Delayed'
            ? '/images/negEllipse.svg'
            : '/images/posEllipse.svg';

    const statusImage = nextPayment
        ? repaymentStatusImg
        : '/images/posEllipse.svg';

    // const getPaymentSchedule = () => {
    //     return (
    //         <PaymentSchedule
    //             show={showPaySchedule}
    //             onClose={() => setShowPaySchedule(false)}
    //             paySchedule={opportunity.paymentSchedule}
    //             returns={opportunity.returns}
    //             units={investment.unitsPurchased}
    //             unitPrice={investment.unitPrice}
    //             viewInvestments={true}
    //             id="closeRepaymentSchedule"
    //         />
    //     );
    // };

    return (
        <>
            <div>
                <Card
                    className="my-2 bg-basicWhite md:my-4"
                    paddingClass="p-0"
                    children={
                        <div className="flex flex-col w-full ">
                            {/* top container */}
                            <div className="flex flex-col w-full text-left lg:flex-row lg:justify-between ">
                                <div className="w-full p-4 border-b lg:border-r lg:w-[70%]">
                                    <div className="flex w-full ">
                                        {/* <div className="flex items-center justify-center w-12 h-12 p-2 mt-1 mr-4 border-2 rounded-lg lg:p-4 lg:w-16 lg:h-16"> */}
                                        <div className="flex items-center justify-center w-12 h-12 px-2 mr-3 bg-basicWhite border rounded-lg shadow-sm shrink-0 md:w-16 md:h-16 ">
                                            <Image
                                                src={getConterPartyLogo(
                                                    opportunity
                                                )}
                                                className="object-scale-down w-full rounded-lg"
                                            />
                                        </div>
                                        <div className="flex flex-col flex-wrap items-start justify-start w-full transition-all duration-200 ease-in-out lg:justify-end">
                                            <div className="flex flex-col flex-wrap items-start lg:items-center lg:flex-row">
                                                <div className="mb-1 text-lg font-bold md:text-xl lg:mr-2 ">
                                                    {getConterPartyName(
                                                        opportunity
                                                    )}
                                                </div>
                                                <div className="flex items-center px-4 mb-1 bg-gray-200 rounded-full h-7 ">
                                                    <Text
                                                        content={
                                                            opportunity
                                                                .industryType
                                                                .name
                                                        }
                                                        size="lg"
                                                        className="text-sm font-medium text-gray-600 lg:text-base whitespace-nowrap"
                                                    />
                                                </div>
                                                <div className="flex items-center py-2 lg:px-5">
                                                    <Text
                                                        content="ID:"
                                                        className="text-sm font-semibold text-gray-900 md:text-base whitespace-nowrap"
                                                    />
                                                    <Text
                                                        content={
                                                            opportunity.slug
                                                        }
                                                        size="lg"
                                                        className="px-1 text-sm font-semibold text-gray-900 break-all line-clamp-1 md:text-base "
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-sm text-gray-500 line-clamp-3 md:line-clamp-2 lg:line-clamp-1 lg:text-base hover:line-clamp-none">
                                                {opportunity.title}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:w-[30%] border-b">
                                    <div className="flex flex-grow w-full lg:h-full">
                                        <div className="flex flex-col flex-grow p-4 border-r lg:justify-center lg:items-start ">
                                            {/* <div className="flex flex-col justify-center flex-grow w-full h-full p-4 lg:justify-end lg:items-start"> */}
                                            {/* <div className="flex items-center flex-grow min-w-[50%] border-r">
                                            <div className="flex flex-col justify-center flex-grow w-full h-full p-4 lg:justify-end lg:items-start"> */}
                                            <Text
                                                content="Asset Class"
                                                className="text-sm text-gray-400 font-normal md:text-base "
                                            />
                                            <Text
                                                content={
                                                    opportunity.displayAssetClass
                                                }
                                                size="lg"
                                                className="text-sm font-semibold text-gray-900 break-all md:text-base whitespace-nowrap "
                                            />
                                            {/* </div> */}
                                        </div>
                                        <div className="flex flex-col flex-grow p-4 lg:justify-center lg:items-start">
                                            <Text
                                                content="Status"
                                                className="text-sm text-gray-400 font-normal md:text-base "
                                            />
                                            <div class="flex items-center">
                                                <Image
                                                    src={statusImage}
                                                    width="12 "
                                                    height="12"
                                                    className="inline mr-2 "
                                                />
                                                <Text
                                                    content={status}
                                                    className="text-sm font-semibold text-gray-900 break-word md:text-base"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Middle COntainer */}
                            <div className="flex-grow w-full border-b lg:border-b-0">
                                <div className="flex flex-wrap items-center ">
                                    <div className="flex flex-col justify-center  w-1/2 lg:px-4 p-4   md:max-w-1/3 lg:max-w-[18%] lg:min-w-[100px] lg:w-auto lg:h-20 flex-grow">
                                        <Text
                                            content="Invested Amount"
                                            className="text-sm md:text-base text-gray-400 font-normal"
                                        />
                                        <Text
                                            content={`₹ ${getNumberWithComma(
                                                investment.investedAmount
                                            )}`}
                                            className="text-base font-semibold text-gray-900 md:text-lg"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-center  w-1/2 lg:px-4 p-4   md:max-w-1/3 lg:max-w-[14%] lg:min-w-[100px] lg:w-auto lg:h-20 flex-grow">
                                        <Text
                                            content={`Number of units`}
                                            className="text-sm md:text-base text-gray-400 font-normal"
                                        />
                                        <Text
                                            content={Number(
                                                investment.unitsPurchased
                                            )}
                                            className="text-base font-semibold text-gray-900 md:text-lg"
                                        />
                                    </div>

                                    <div className="flex flex-col justify-center  w-1/2 lg:px-4 p-4   md:max-w-1/3 lg:max-w-[18%] lg:min-w-[100px] lg:w-auto lg:h-20 flex-grow">
                                        <Text
                                            content="Investment Date"
                                            className="text-sm md:text-base text-gray-400 font-normal"
                                        />
                                        <Text
                                            content={Moment(
                                                investment.investmentDate
                                            ).format('D MMM YYYY ')}
                                            className="text-base font-semibold text-gray-900 md:text-lg"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-center  w-1/2 lg:px-4 p-4   md:max-w-1/3 lg:max-w-[14%] lg:min-w-[100px] lg:w-auto lg:h-20 flex-grow">
                                        <Text
                                            content="Tenure"
                                            className="text-sm md:text-base text-gray-400 font-normal"
                                        />
                                        <Text
                                            content={tenure}
                                            className="text-base font-semibold text-gray-900 md:text-lg"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-center  w-1/2 lg:px-4 p-4   md:max-w-1/3 lg:max-w-[14%] lg:min-w-[100px] lg:w-auto lg:h-20 flex-grow">
                                        <Text
                                            content={'Pre-Tax IRR'}
                                            className="text-sm md:text-base text-gray-400 font-normal"
                                        />
                                        <Text
                                            content={`${opportunity.displayIRR}%`}
                                            className="text-base font-semibold text-gray-900 md:text-lg"
                                        />
                                    </div>

                                    {investment.realisedIRR > 0 && (
                                        <div className="flex flex-col justify-center  w-1/2 lg:px-4 p-4   md:max-w-1/3 lg:max-w-[18%] lg:min-w-[100px] lg:w-auto lg:h-20 flex-grow">
                                            <Text
                                                content={'Pre-Tax Realised IRR'}
                                                className="text-sm md:text-base text-gray-400 font-normal"
                                            />
                                            <Text
                                                content={`${investment.realisedIRR.toFixed(
                                                    2
                                                )}%`}
                                                className="text-base font-semibold text-gray-900 md:text-lg"
                                            />
                                        </div>
                                    )}

                                    {nextPayment && (
                                        <div className="flex flex-col justify-center  w-1/2 lg:px-4 p-4   md:max-w-1/3 lg:max-w-[18%] lg:min-w-[100px] lg:w-auto lg:h-20 flex-grow">
                                            <Text
                                                content={`Next payment due on ${opportunity.slug ===
                                                    'IDZW001'
                                                    ? '*'
                                                    : ''
                                                    }`}
                                                className="text-sm md:text-base text-gray-400 font-normal"
                                            />
                                            <Text
                                                content={`${nextRepaymentDate(
                                                    nextPayment
                                                )}`}
                                                className="text-base font-semibold text-gray-900 md:text-lg"
                                            />
                                        </div>
                                    )}
                                    <div className="flex flex-col justify-center  w-1/2 lg:px-4 p-4   md:max-w-1/3 lg:max-w-[18%] lg:min-w-[100px] lg:w-auto lg:h-20 flex-grow">
                                        <Text
                                            content="Gross Return Received"
                                            className="text-sm md:text-base text-gray-400 font-normal"
                                        />
                                        <Text
                                            content={`₹ ${grossPayment}`}
                                            className="text-base font-semibold text-gray-900 md:text-lg"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* bottom COntainer */}
                            <div className="flex flex-col border-b">
                                <div className="w-full px-4 pb-4 ">
                                    <Text
                                        content="All repayments related to your investment in this opportunity will be transferred directly to the bank account linked to your Demat Account."
                                        className="text-sm text-gray-400"
                                    />
                                </div>

                                {(opportunity.slug === 'IDZW001' ||
                                    opportunity.slug === 'IDZW002' ||
                                    opportunity.slug === 'IDZW003' ||
                                    opportunity.slug === 'IDZW004' ||
                                    opportunity.slug === 'IDZW005') && (
                                        <div className="w-full px-4 pb-4 border-t md:border-none">
                                            <Text
                                                content="* Repayment date mentioned will vary depending on your investment date. Actual repayment date will be 90 days from the next day of your investment."
                                                className="text-sm text-gray-400"
                                            />
                                        </div>
                                    )}
                            </div>
                            <div className="flex flex-col p-4 md:flex-row md:justify-end">
                                <div className="w-full md:max-w-[300px]">
                                    <Button
                                        id="viewRepaymentSchedule"
                                        design="standard"
                                        type="ghost"
                                        children={
                                            <>
                                                <Image
                                                    src="/images/Show.svg"
                                                    className="pr-2"
                                                    alt="view"
                                                />
                                                view repayment schedule
                                            </>
                                        }
                                        paddingClass="py-3 px-4"
                                        className="flex items-center justify-center w-full text-sm font-semibold text-blue-900 border-blue-900 rounded md:-ml-4 md:text-base"
                                        onClick={() => setShowPaySchedule(true)}
                                    />
                                </div>

                                {investment.realisedIRR > 0 && (
                                    <div className="w-full md:max-w-[300px] mr-6 z-100">
                                        <DownloadRealisedIRRDetails
                                            investmentId={investment.id}
                                        />
                                    </div>
                                )}

                                <div className="w-full md:max-w-[300px]">
                                    <Button
                                        design="standard"
                                        type="ghost"
                                        children={
                                            <>
                                                <Image
                                                    src="/images/Show.svg"
                                                    className="pr-2"
                                                    alt="view"
                                                />
                                                <span
                                                    class="inline-block truncate"
                                                    title={
                                                        'view the securities transfer agreement'
                                                    }
                                                >
                                                    view the securities transfer
                                                    agreement
                                                </span>
                                                {loading && (
                                                    <div className="relative inline-block w-4 h-4 ml-2 border-b-2 rounded-full border-primary-500 animate-spin"></div>
                                                )}
                                            </>
                                        }
                                        paddingClass="py-3 px-4"
                                        className={`flex items-center justify-center w-full mt-4 text-sm font-semibold text-blue-900 border-blue-900 rounded md:mt-0 md:text-base ${loading && 'disabled'
                                            }`}
                                        onClick={() =>
                                            !loading &&
                                            downloadOrGenerateDocument()
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
            {/* {getPaymentSchedule()} */}
        </>
    );
};

export default NewInvestmentSummeryVertical;
