import {getInitials} from '@helpers/miscelleanous';
import {useEffect, useState} from 'react';

const useNameAvatar = (name) => {
    const [avatar, setAvatar] = useState('')

    useEffect(() => {
        if (name) {
            setAvatar(getInitials(name || ""));
        }
    }, [name])

    return { avatar }
}

export default useNameAvatar
