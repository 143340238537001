const Input = ({
    register,
    id,
    type,
    labelClass,
    placeholder,
    label,
    error,
    onClick,
    onPaste,
    onBlur,
    borderWeight,
    focusStyle,
    borderColor = 'border-gray-300',
    inputpaddingClassName = 'px-4 py-3',
    errorClassName = '',
    readOnly = false,
    ...inputProps
}) => {
    return (
        <>
            {label && (
                <label
                    className={`block w-full text-left text-gray-900 ${labelClass}`}
                >
                    {label}
                </label>
            )}
            <input
                {...register(id)}
                id={id}
                {...inputProps}
                type={type}
                onClick={onClick}
                onPaste={onPaste}
                onBlur={onBlur}
                className={`${
                    error ? 'border-semantic-error-base' : borderColor
                } ${borderWeight ? borderWeight : 'border-1'} ${
                    inputProps.width ? inputProps.width : 'w-full'
                } ${
                    inputProps.height ? inputProps.height : 'h-12'
                } ${inputpaddingClassName} rounded-lg mt-2 box-border ${
                    inputProps.className
                } ${focusStyle ? focusStyle : ''} ${
                    inputProps?.disabled ? '!bg-gray-100' : ''
                }`}
                placeholder={placeholder}
                readOnly={readOnly}
                disabled={inputProps?.disabled || false}
            />
            {error && (
                <div
                    className={`text-left text-semantic-error-base ${errorClassName}`}
                >
                    {error.message}
                </div>
            )}
        </>
    );
};

export default Input;
