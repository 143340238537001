import { useState } from 'react';

const ReadMore = ({
    children,
    textBeforeReadmore,

    ...props
}) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <>
            {isReadMore ? textBeforeReadmore : children}

            <a
                href="javascript:void(0)"
                onClick={toggleReadMore}
                className={`underline mx-1 font-medium  ${props.isLightFooter ? 'text-gray-700' : 'text-basicWhite'
                    }
                ${isReadMore ? 'mt-0' : 'inline-block mt-2'}
                `}
            >
                {isReadMore ? 'Read more' : ' Read less'}
            </a>
        </>
    );
};

export default ReadMore;
