import LazyImage from '@atoms/LazyImage';
import Avatar from '@atoms/Avatar';
import useNameAvatar from '@hooks/useNameAvatar';
import { Link, Text } from '@ui/atoms';
import NavigationPannel from './NavigationPannel';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { UserIcon } from '@heroicons/react/solid';
import 'animate.css/animate.min.css';
import { useWindowSize } from '@uidotdev/usehooks';
import useToggleBodyOverflow from '@hooks/useToggleBodyOverflow';
import { secondLevelPageDetails } from '@helpers/secondLevelPages';
import { showNudgeForUpdateVerifiedKycFlow, triggerCustomGaEvent } from '@helpers/miscelleanous';
import { navigationGaEventsName } from '@helpers/ga-events-name-enum';
import useKycStore from '@store/useKycStore';
import { useMemo } from 'react';

const WithAuthHeader = ({
    name,
    menuOpen,
    handleMenu,
    rmDetails,
    menu,
    isStaticPage,
    logout,
    kycStatus,
    showRMDetails
}) => {
    const { height } = useWindowSize();
    const { avatar } = useNameAvatar(name);
    const router = useRouter();
    const pageProps = secondLevelPageDetails[router?.pathname];
    useToggleBodyOverflow(menuOpen, [menuOpen]);
    const { kycFlowStatus, showKycBanner } = useKycStore()

    const showNudgeForNewStepsAdded = useMemo(
        () => showKycBanner && showNudgeForUpdateVerifiedKycFlow(kycStatus, kycFlowStatus),
        [kycStatus, kycFlowStatus, showKycBanner]
    );


    return (
        <div className="flex items-center relative justify-between w-full xl:hidden">
            {!pageProps ? (
                <div className="flex items-center justify-between w-full md:px-8">
                    <div className={`flex items-center w-full`}>
                        <Link href="/">
                            <LazyImage
                                src={'/images/logo.svg'}
                                className="w-[120px] h-7 relative"
                                alt="logo"
                            />
                        </Link>
                    </div>

                    {!menuOpen ? (
                        <div
                            className="w-8 h-8"
                            onClick={() => {
                                handleMenu();
                                triggerCustomGaEvent(navigationGaEventsName.CLICKED_INVESTOR_INITIAL_MOBILE_WEB_NAVIGATION)
                            }}
                        >
                            <Avatar
                                icon={
                                    avatar || <UserIcon className="w-5 h-5" />
                                }
                            />
                        </div>
                    ) : (
                        <button
                            onClick={handleMenu}
                            className="outline-none mobile-menu-button"
                        >
                            <LazyImage
                                src={'/images/close-hamburger.svg'}
                                alt={'close'}
                                className="relative w-6 h-6"
                            />
                        </button>
                    )}

                    <NavigationPannel
                        {...{
                            menuOpen,
                            height,
                            rmDetails,
                            avatar,
                            name,
                            isStaticPage,
                            logout,
                            handleMenu,
                            menu,
                            kycStatus,
                            showRMDetails,
                            showNudgeForNewStepsAdded
                        }}
                    />
                </div>
            ) : (
                <div className="flex items-center w-full">
                    <button
                        className="w-5 h-5 absolute"
                        onClick={() => {
                            router.back();
                        }}
                    >
                        <ArrowLeftIcon className="w-full h-full text-primary-500" />
                    </button>

                    {pageProps?.title ? (
                        <Text
                            content={pageProps?.title}
                            className="p3-medium text-gray-800 text-center w-full"
                        />
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default WithAuthHeader;