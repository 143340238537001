import router from 'next/router';
import { Image, Link, Avatar } from '@atoms/index';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';

const Header = (props) => {
    const [profileClicked, setProfileClicked] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuList, setMenuList] = useState([]);
    const handleMenu = (event) => {
        event.preventDefault();
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        if (props.entityType && props.entityType !== 'ifa') {
            setMenuList([
                ...props.menu,
                { title: 'Refer & Earn', url: '/refer-and-earn' },
            ]);
        } else {
            setMenuList(props.menu);
        }
    }, [props.entityType]);
    return (
        <div className="sticky top-0 z-30 justify-between p-1 bg-basicWhite shadow-md">
            <div className="container mx-auto md:px-4 lg:px-3 xl:px-20 2xl:px-28">
                <div className="grid items-center grid-cols-3 gap-4 py-2 md:grid-cols-3 lg:py-0">
                    <div className="pl-4 md:hidden">
                        <div className="flex items-center md:hidden">
                            <button
                                onClick={() => handleMenu()}
                                className="outline-none mobile-menu-button"
                            >
                                <svg
                                    className="w-6 h-6 text-gray-500"
                                    x-show="!showMenu"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                                </svg>
                            </button>
                        </div>
                        <div
                            className={`${menuOpen
                                ? 'mobile-menu-visible'
                                : 'mobile-menu-hidden'
                                } fixed top-0 left-0 z-50 md:hidden mobile-menu`}
                        >
                            <div className="fixed w-full min-h-full bg-gray-400 bg-opacity-50 bg-blend-overlay "></div>
                            <div className="fixed z-50 h-full max-h-full min-h-full pt-6 bg-basicWhite menu mobile-menu-content w-72">
                                <div className="absolute right-6">
                                    <button onClick={() => handleMenu()}>
                                        <Image
                                            src="/images/close-hamburger.svg"
                                            width="24"
                                            height="24"
                                        />
                                    </button>
                                </div>

                                <Image
                                    src="/logo.svg"
                                    className="mx-5 mb-8 max-h-6"
                                />

                                <ul className="pl-3">
                                    {menuList.map((item) => (
                                        <li key={item.url}>
                                            <Link
                                                href={item.url}
                                                onClick={() => {
                                                    router.push(item.url);
                                                    props.handleMenu();
                                                }}
                                                id={
                                                    `navbar` +
                                                    item.title +
                                                    `Button`
                                                }
                                                className="block px-3 py-4 text-sm transition duration-300 hover:bg-secondary-500"
                                                children={item.title}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="flex-initial text-left">
                        <Image src="/logo.svg" />
                    </div>
                    <div className="block mr-4 text-right md:hidden">
                        <Link
                            onClick={() => {
                                setProfileClicked(!profileClicked);
                            }}
                            href="#"
                            children={
                                <>
                                    {props.avatar ? (
                                        <Avatar icon={props.avatar} />
                                    ) : (
                                        <div className="inline-block ">
                                            <div className="w-8 h-8 p-2 rounded-full bg-secondary-400">
                                                <Image
                                                    src="/images/user-solid.svg"
                                                    className="w-full h-full"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {profileClicked && (
                                        <div
                                            className={`absolute right-1 w-28 text-center bg-basicWhite p-1 border rounded-md sm:top-20 sm:right-4 md:right-8 lg:right-0 lg:top-9`}
                                        >
                                            <button
                                                className="w-full mb-2 hover:bg-gray-200"
                                                onClick={() => {
                                                    router.push('/profile');
                                                }}
                                                id="myProileButton"
                                            >
                                                My Profile
                                            </button>
                                            <button
                                                className="w-full hover:bg-gray-200"
                                                onClick={props.logout}
                                                id="logoutButton"
                                            >
                                                Logout
                                            </button>
                                        </div>
                                    )}
                                </>
                            }
                        />
                    </div>
                    <div className="flex-initial hidden text-right md:col-span-2 xs:hidden md:block lg:block xl:block">
                        <nav className="p-1 lg:p-5">
                            {menuList.map((item) => (
                                <Link
                                    key={item.url}
                                    href={item.url}
                                    children={item.title}
                                    className="p-1 px-5"
                                    size="base"
                                    id={`navbar` + item.title + `Button`}
                                />
                            ))}
                            {props.avatar ? (
                                <Avatar
                                    icon={props.avatar}
                                    className="hidden ml-5 align-middle md:inline-block"
                                />
                            ) : (
                                <div className="hidden ml-5 align-middle md:inline-block ">
                                    <div className="w-8 h-8 p-2 rounded-full bg-secondary-400">
                                        <Image
                                            src="/images/user-solid.svg"
                                            className="w-full h-full"
                                        />
                                    </div>
                                </div>
                            )}
                            <Link
                                onClick={() => {
                                    setProfileClicked(!profileClicked);
                                }}
                                href="#"
                                children={
                                    <>
                                        <span
                                            className="self-center inline-block truncate align-middle header-username"
                                            title={props.name}
                                        >
                                            {props.name}
                                        </span>
                                        {profileClicked ? (
                                            <ChevronUpIcon className="inline w-5 h-5" />
                                        ) : (
                                            <ChevronDownIcon className="inline w-5 h-5 pl-1" />
                                        )}
                                        {profileClicked && (
                                            <div
                                                className={`absolute top-5 right-0 w-28 text-center bg-basicWhite p-1 border rounded-md sm:top-20 sm:right-4 md:right-8 lg:right-0 lg:top-9 shrink-0`}
                                            >
                                                <button
                                                    className="w-full mb-2 hover:bg-gray-200"
                                                    onClick={() => {
                                                        router.push('/profile');
                                                    }}
                                                    id="myProileButton"
                                                >
                                                    My Profile
                                                </button>
                                                <button
                                                    className="w-full hover:bg-gray-200"
                                                    onClick={props.logout}
                                                    id="logoutButton"
                                                >
                                                    Logout
                                                </button>
                                            </div>
                                        )}
                                    </>
                                }
                                className="relative p-1"
                                size="base"
                            />
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
