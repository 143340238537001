import { UrlParamsReplace } from './index';

export const GET_INVESTOR_OPPORTUNITY_DOCUMENT = (
    entityId,
    opportunityId,
    type
) =>
    UrlParamsReplace(
        '/investors/:entityId/investor-opportunity-documents?opportunityId=:opportunityId&type=:type',
        { entityId, opportunityId, type }
    );

export const GET_INVESTOR_OPPORTUNITY_DOCUMENT_BY_ENTITY_OPPORTYNITY_ID_URL = (
    entityId,
    opportunityId,
    investmentId,
    type
) =>
    UrlParamsReplace(
        '/investors/:entityId/investor-opportunity-documents?opportunityId=:opportunityId&investmentId=:investmentId&type=:type',
        { entityId, opportunityId, investmentId, type }
    );

export const CREATE_INVESTOR_OPPORTUNITY_DOCUMENT_URL = () =>
    UrlParamsReplace('/investor-opportunity-documents');

export const CREATE_APPENDED_SIGNED_TRANSACTION_DOCUMENT_URL = () =>
    UrlParamsReplace(
        '/investor-opportunity-documents/appended-signed-transaction-document'
    );

export const CREATE_AND_GET_APPENDED_SIGNED_TRANSACTION_DOCUMENT_URL = () =>
    UrlParamsReplace(
        '/investor-opportunity-documents/appended-signed-transaction-document/synchronous'
    );
