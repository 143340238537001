import React from 'react';

const Loader = ({ size = 'w-12 h-12', borderWidth = 'border-b-4' }) => {
    return (
        <div
            className={`${size} ${borderWidth} rounded-full border-primary-600 animate-spin`}
        ></div>
    );
};

export default Loader;
