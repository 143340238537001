import { Text } from '@atoms/index';
import { getDecimalNumberWithComma } from '@helpers/miscelleanous';
import Moment from 'moment';
import React from 'react';

const Refunds = ({ payment }) => {
    return (
        <div>
            <div className="font-medium text-center mx-4 my-4 p-1 text-semantic-success-base bg-green-100">
                Refund
            </div>
            <div className="flex justify-between flex-col md:flex-row px-4">
                <div className="flex justify-between">
                    <Text
                        content="Total Refund Received"
                        className="font-medium"
                    />
                    <Text
                        content={`₹${getDecimalNumberWithComma(
                            payment.amount,
                            2
                        )}`}
                        className="pl-4"
                    />
                </div>
                <div className="flex justify-between my-4 md:my-0">
                    <Text content="Date of Refund" className="font-medium" />
                    <Text
                        content={Moment(payment.refundDate).format(
                            'DD MMM YYYY'
                        )}
                        className="pl-4"
                    />
                </div>
            </div>
        </div>
    );
};
export default Refunds;
