const Checkbox = ({
    register,
    id,
    type,
    label,
    error,
    labelClass,
    onClick,
    gtmtag = '',
    ...inputProps
}) => {
    return (
        <>
            {label && (
                <label
                    className={`flex justify-start text-left text-gray-900 py-4 ${inputProps.wrapperclass}`}
                >
                    <input
                        {...register(id)}
                        id={id}
                        {...inputProps}
                        type="checkbox"
                        onClick={onClick}
                        gtmtag={gtmtag ? gtmtag : id}
                        className={`${error
                                ? 'border-semantic-error-base'
                                : 'border-gray-300'
                            } border-2 ${inputProps.width ? inputProps.width : 'w-full'
                            } ${inputProps.height ? inputProps.height : 'h-12'
                            }  mx-4 rounded-lg  box-border self-center ${inputProps.className
                            }`}
                    />
                    <div className={`text-gray-500 ${labelClass}`}>{label}</div>
                </label>
            )}

            {error && (
                <div className="text-left text-semantic-error-base p4-medium">
                    {error.message}
                </div>
            )}
        </>
    );
};

export default Checkbox;
