import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

const FilterHead = ({
    label,
    isVisible,
    canReset,
    labelClassName = '',
    canCollapsible = false,
    onReset = () => { },
    setIsVisible = () => { },
}) => {
    return (
        <div
            className={
                labelClassName
                    ? labelClassName
                    : 'flex justify-between p-2 items-center'
            }
        >
            {label}
            <div className="flex gap-2 items-center justify-center">
                {canReset && isVisible ? (
                    <button
                        className="px-4 text-primary-500 rounded-full font-semibold"
                        type="button"
                        onClick={onReset}
                    >
                        Reset
                    </button>
                ) : null}
                {canCollapsible ? (
                    <button
                        className="text-primary-500"
                        type="button"
                        onClick={() => setIsVisible(!isVisible)}
                    >
                        {isVisible ? (
                            <ChevronUpIcon className="w-5 h-5" />
                        ) : (
                            <ChevronDownIcon className="w-5 h-5" />
                        )}
                    </button>
                ) : null}
            </div>
        </div>
    );
};

export default FilterHead;
