import Text from './Text';
import Image from './Image';

const MessageToaster = ({ message, error, ...props }) => {
    return (
        <div
            className={`w-full py-4 px-5 mt-8 rounded flex ${error ? 'bg-semantic-error-base' : props.bg ? props.bg : 'bg-leafGreen-600'
                } text-basicWhite ${props.className}`}
        >
            {error ? (
                <Image src="/images/exclamation-circle-solid.svg" />
            ) : (
                <Image src="/images/check-circle.svg" />
            )}
            <Text content={message} className="pl-4" />
        </div>
    );
};

export default MessageToaster;
