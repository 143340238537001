import { useState, useEffect } from 'react';

const useMultiCheckboxOrRadioBox = ({
    defaultSelected = [],
    options = [],
    type = 'radio',
    canCollapsible,
    onSelect = () => {},
}) => {
    const [selected, setSelected] = useState(defaultSelected);
    const [isVisible, setIsVisible] = useState(true);
    const onChange = (e, option) => {
        let values = [];
        if (option?.value === 'all') {
            if (e.target.checked)
                values = options
                    .map((it) => it.value)
                    .filter((it) => it !== 'all');
            else values = [];
        } else {
            if (e.target.checked) {
                if (type === 'radio') values = [option.value];
                else if (type === 'checkbox')
                    values = [...selected, option.value];
            } else {
                values = selected.filter((item) => item !== option.value);
            }
        }

        setSelected(values);
        onSelect(values);
    };

    useEffect(() => {
        setSelected(defaultSelected);
    }, [defaultSelected]);

    useEffect(() => {
        if (selected?.length) {
            setIsVisible(true);
            onSelect(selected);
        }
    }, [selected]);

    // onReset
    const onReset = () => {
        setSelected([]);
        onSelect([]);
    };

    return {
        selected,
        isVisible,
        setIsVisible,
        onChange,
        setSelected,
        onReset,
    };
};

export default useMultiCheckboxOrRadioBox;
