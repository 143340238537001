import { Text, Image } from '@atoms/index';
import Moment from 'moment';
import {
    getDecimalNumberWithComma,
    getStatusBadgeStyles,
    getPaymentStatus,
} from '@helpers/miscelleanous';
import Refunds from '../Refunds';
import texts from './en.json';

const PaymentScheduleDesktopView = ({
    viewInvestments,
    returns,
    paySchedule,
    totalAmount,
    payScheduleDateWithInterestObj,
    units,
    selectedPayment,
    setSelectedPayment,
    getTotalReturnReceived,
    setShowDetails,
    showDetails,
    showDetailsStatusMessage,
    showDetailsStatusArrow,
}) => {
    return (
        <div className="hidden lg:w-full lg:px-4 lg:flex lg:flex-col my-3 lg:text-sm ">
            {!viewInvestments && (
                <div className="w-full grid grid-cols-[30px_75px_120px_100px] md:grid-cols-4 bg-gray-100 py-3 font-medium my-2 px-2 md:px-4 rounded sticky -top-6">
                    <Text content={texts.No} className="text-left p5-semibold text-basicBlack"/>
                    <Text content={texts.Date} className="text-left p5-semibold text-basicBlack"/>
                    <Text content={texts.Principal} className="text-right p5-semibold text-basicBlack"/>
                    <Text content={texts.Interest} className="text-right p5-semibold text-basicBlack"/>

                </div>
            )}

            {paySchedule.map((payment, index) => {
                return (
                    <>
                        <div className="py-2 px-2 md:px-4 grid grid-cols-[30px_75px_120px_100px] md:grid-cols-4 auto-cols-min content-center">
                            <Text content={index + 1} className="text-left text-gray-700 p4-regular"/>

                            {/* <div
                                className={`flex items-center  w-full ${
                                    viewInvestments
                                        ? 'sm:pl-4 pl-1'
                                        : 'pl-8 pr-3'
                                }`}
                            > */}
                                {/* <div
                                    className={`flex ${
                                        viewInvestments
                                            ? 'flex-col'
                                            : 'flex-row justify-between w-full'
                                    }`}
                                > */}
                                    <Text
                                        content={
                                            payment.formattedDate
                                                 
                                        }
                                        className="text-gray-700 p4-regular text-left"
                                    />
                                        <Text
                                            content={`₹ ${getDecimalNumberWithComma(
                                                payment.totalPrincipal,
                                                2
                                            )}`}
                                            className="text-gray-700 p4-regular text-right"
                                        />
                                        <Text
                                            content={`₹ ${getDecimalNumberWithComma(
                                                payment.totalInterest,
                                                2
                                            )}`}
                                            className="text-gray-700 p4-regular text-right"
                                        />
                                    
                                {/* </div> */}
                                {viewInvestments && (
                                    <div className="flex flex-col">
                                        <div
                                            className={`rounded px-2 py-1 self-end ${getStatusBadgeStyles(
                                                getPaymentStatus(payment)
                                            )}`}
                                        >
                                            <Text
                                                content={getPaymentStatus(
                                                    payment
                                                )}
                                                className="font-medium"
                                                size="sm"
                                            />
                                        </div>

                                        {payment.status !== 'Received' ? (
                                            <div className="text-center text-gray-400">
                                                -
                                            </div>
                                        ) : (
                                            <button
                                                className="pt-1 text-xs text-secondary-500"
                                                onClick={() => {
                                                    setSelectedPayment(payment);
                                                    setShowDetails(
                                                        !showDetails
                                                    );
                                                }}
                                            >
                                                <Text
                                                    content={showDetailsStatusMessage(
                                                        payment
                                                    )}
                                                    className="inline pr-1 2xl:pr-3"
                                                />
                                                <Image
                                                    src={showDetailsStatusArrow(
                                                        payment
                                                    )}
                                                    className="inline"
                                                />
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        {/* </div> */}
                        {selectedPayment === payment && showDetails && (
                            <div className="flex flex-col md:pb-5 text-xs border-t-2 border-gray-100 bg-gray-50">
                                {payment.type === 'Refund' ? (
                                    <Refunds payment={payment} />
                                ) : (
                                    <div className="flex flex-col p-2">
                                        <div className="flex items-center justify-between pb-4">
                                            <Text
                                                content="Expected Amount"
                                                className="text-gray-500"
                                            />
                                            <Text
                                                content={
                                                    payment.type ===
                                                        'OutOfSchedule'
                                                        ? '-'
                                                        : `₹ ${getDecimalNumberWithComma(
                                                            Number(units) *
                                                            payment.cashFlow,
                                                            2
                                                        )}`
                                                }
                                                className="font-medium"
                                            />
                                        </div>
                                        <div className="flex items-center justify-between pb-4">
                                            <Text
                                                content="Expected Date"
                                                className="text-gray-500"
                                            />
                                            <Text
                                                content={
                                                    payment.type ===
                                                        'OutOfSchedule'
                                                        ? '-'
                                                        : payment.date
                                                            
                                                }
                                                className="font-medium"
                                            />
                                        </div>
                                        <div className="flex items-center justify-between pb-4">
                                            <Text
                                                content="Total Gross Amount Received"
                                                className="text-gray-500"
                                            />
                                            <Text
                                                content={`₹ ${getDecimalNumberWithComma(
                                                    Number(units) *
                                                    payment.cashFlow,
                                                    2
                                                )}`}
                                                className="font-medium"
                                            />
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <Text
                                                content="Received Date"
                                                className="text-gray-500"
                                            />
                                            <Text
                                                content={payment.date}
                                                className="font-medium"
                                            />
                                        </div>
                                    </div>
                                )}
                                {payment.type === 'OutOfSchedule' && (
                                    <div className="border-t-2 border-gray-100 py-3 px-2">
                                        <div className="py-2 rounded-md font-semibold text-center bg-green-100 text-semantic-success-base ">
                                            Out Of Schedule
                                        </div>
                                    </div>
                                )}
                                {isInterest && (
                                    <div className="flex px-2 py-3 border border-t-2 border-gray-100">
                                        <Image
                                            src="/images/info-new.svg"
                                            className="pr-3"
                                        />
                                        <Text
                                            content="Note: The amount received in your bank account will be net of applicable TDS"
                                            className="text-gray-500"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                );
            })}

            <div
                className={`grid ${
                    viewInvestments ? 'grid-cols-5' : 'grid-cols-3'
                } col-start-auto gap-2 px-2 md:px-4 py-3 text-sm bg-gray-100 rounded-b-lg`}
            >
                <div className="col-span-1">
                    <Text content="Total" className="p5-semibold break-all" />
                </div>
                <Text
                    content={`₹ ${getDecimalNumberWithComma(
                        Number(units) * totalAmount,
                        2
                    )}`}
                    className="font-medium col-span-2 break-all text-right"
                />
                {viewInvestments && (
                    <Text
                        content={`₹ ${getDecimalNumberWithComma(
                            getTotalReturnReceived(),
                            2
                        )}`}
                        className="p4-semibold col-span-2 break-all text-right"
                    />
                )}
            </div>
        </div>
    );
};
export default PaymentScheduleDesktopView;
