import { Text } from '@atoms/index';
import { AnimatePresence, motion } from 'framer-motion';
import { opportunitiesFilterTypes } from '@helpers/enums/opportunities-filter-types';
import Button from '@ui/atoms/design-system/Button';
import { useRouter } from 'next/router';
import { Image } from '@ui/atoms';
import { useEffect, useState } from 'react';
import useWindowDimensions from '@ui/hooks/useWindowDimensions';
import { opportunitiesGaEventsName } from '@helpers/ga-events-name-enum';
import { triggerCustomGaEvent } from '@helpers/miscelleanous';

const OpportunityOverview = ({ showActiveOpportunities = true, activeButton, filter, stats, isUnlistedSDI, setIsUnlistedSDI, ...property }) => {
    const router = useRouter();
    const [descriptionText, setDescriptionText] = useState('');
    const { width } = useWindowDimensions();

    const oppportunitiesTabs = {
        BONDS: 'Bonds',
        SDIS: 'SDIs',
        TBILLS: 'T-Bills'
    };


    const sdiTabs = {
        RBI_REGULATED: 'RBI Regulated',
        SEBI_REGULATED: 'SEBI Regulated',
    };

    const tabs = [
        {
            id: opportunitiesFilterTypes.BOND,
            value: oppportunitiesTabs.BONDS,
            count: stats?.bonds || 0,
        },
        {
            id: opportunitiesFilterTypes.SDI,
            value: oppportunitiesTabs.SDIS,
            count: (stats?.sebiSDIs || 0) + (stats?.rbiSDIs || 0),
        },
        {
            id: opportunitiesFilterTypes.T_BILL,
            value: oppportunitiesTabs.TBILLS,
            count: stats?.tBills || 0,
        },
    ];

    const regulationTabs = [
        {
            id: "rbi",
            value: sdiTabs.RBI_REGULATED,
            count: stats?.rbiSDIs || 0,
            onClick: () => {
                setIsUnlistedSDI(true);
                triggerGaEventsForsdiTabs(sdiTabs.RBI_REGULATED);
            },
        },
        {
            id: "sebi",
            value: sdiTabs.SEBI_REGULATED,
            count: stats?.sebiSDIs || 0,
            onClick: () => {
                setIsUnlistedSDI(false);
                triggerGaEventsForsdiTabs(sdiTabs.SEBI_REGULATED);
                
            },
        },
    ];

    useEffect(() => {
        switch (filter) {
            case opportunitiesFilterTypes.BOND: setDescriptionText('Corporate bonds are fixed-income generating instruments and the returns are not linked to the performance of the stock market. These are a form of debt security issued by governments, and public and private corporations to raise funds for various purposes.\n\nThese corporate bonds are listed and regulated by SEBI.');
                return;
            case opportunitiesFilterTypes.T_BILL: setDescriptionText('Considered among the best fixed-income investments in India, T-Bills are zero coupon securities offered at a discount to the face value of the security. The full security face value is paid on the due date.\n\nT-Bills are short-term debt instruments from the government with three tenures of 91, 182 or 364 days.');
                return;
            case opportunitiesFilterTypes.SDI: isUnlistedSDI
                ? setDescriptionText('SDIs are securities that represent an ownership interest in a pool of income generating assets, typically loan receivables, invoice receivables or real assets. The income generated from the underlying assets is distributed to the security holders.\n\nUnlisted SDIs are regulated by RBI.')
                : setDescriptionText('SDIs are securities that represent an ownership interest in a pool of income generating assets, typically loan receivables, invoice receivables or real assets. The income generated from the underlying assets is distributed to the security holders.\n\nListed SDIs are regulated by SEBI.');
                return;
            default: setDescriptionText('');
        }
    }, [filter, isUnlistedSDI]);

    const opacity = {
        hidden: {
            opacity: 0,
            zIndex: -100,
            x: -100,
        },
        visible: {
            opacity: 1,
            zIndex: 5,
            transition: {
                duration: 0.1,
                x: 0,
            },
        },
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.1,
            },
        },
    };

    const openOtherOpportunitiesPage = () => {
        if (showActiveOpportunities)
            router.push(`/opportunities/closed`);
        else
            router.push(`/opportunities`);
    }

    const ViewOtherOpportunitiesButton = ({ buttonType, buttonSize, className }) =>
        <Button
            buttonSize={buttonSize}
            buttonType={buttonType}
            buttonText={showActiveOpportunities ? 'View recently closed opportunities' : 'View active opportunities'}
            onClick={openOtherOpportunitiesPage}
            suffixComponent={<Image src="/images/arrow-right-2.svg" alt="arrow" />}
            className={className}
        />

    const triggerGaEventsForsdiTabs = (value) => {
        let eventName;
        if (showActiveOpportunities) {
            switch (value) {
                case sdiTabs.RBI_REGULATED:
                    eventName = opportunitiesGaEventsName.CLICKED_RBI_SDI_TAB_ACTIVE
                    break;
                case sdiTabs.SEBI_REGULATED:
                    eventName = opportunitiesGaEventsName.CLICKED_SEBI_SDI_TAB_ACTIVE
                    break;
                default:
                    break;
            }
        } else {
            switch (value) {
                case sdiTabs.RBI_REGULATED:
                    eventName = opportunitiesGaEventsName.CLICKED_RBI_SDI_TAB_RECENTLY_CLOSED
                    break;
                case sdiTabs.SEBI_REGULATED:
                    eventName = opportunitiesGaEventsName.CLICKED_SEBI_SDI_TAB_RECENTLY_CLOSED
                    break;
                default:
                    break;
            }
        }
        triggerCustomGaEvent(eventName);
    }

    const triggerGaEventsForOpportunitiesTabs = (value) => {
        let eventName;
        if (showActiveOpportunities) {
            switch (value) {
                case oppportunitiesTabs.BONDS:
                    eventName = opportunitiesGaEventsName.CLICKED_VIEW_BONDS_TAB_ACTIVE
                    break;
                case oppportunitiesTabs.SDIS:
                    eventName = opportunitiesGaEventsName.CLICKED_VIEW_SDI_TAB_ACTIVE
                    break;
                case oppportunitiesTabs.TBILLS:
                    eventName = opportunitiesGaEventsName.CLICKED_VIEW_TBILLS_TAB_ACTIVE
                    break;
                default:
                    break;
            }
        } else {
            switch (value) {
                case oppportunitiesTabs.BONDS:
                    eventName = opportunitiesGaEventsName.CLICKED_VIEW_BONDS_TAB_RECENTLY_CLOSED
                    break;
                case oppportunitiesTabs.SDIS:
                    eventName = opportunitiesGaEventsName.CLICKED_VIEW_SDI_TAB_RECENTLY_CLOSED
                    break;
                case oppportunitiesTabs.TBILLS:
                    eventName = opportunitiesGaEventsName.CLICKED_VIEW_TBILLS_TAB_RECENTLY_CLOSED
                    break;
                default:
                    break;
            }
        }
        triggerCustomGaEvent(eventName);
    }

    return (
        <AnimatePresence mode="wait">
            <div className="inline-flex w-full flex-col items-center md:items-start justify-start md:w-[710px] xl:w-[1100px] gap-4">
                <div className={`flex flex-col gap-2 items-center ${showActiveOpportunities ? "md:items-start" : "md:items-start xl:items-center w-full"} px-4 md:px-0`}>
                    <Text
                        content={showActiveOpportunities ? "Active Opportunities" : "Recently Closed Opportunities"}
                        className="text-primary-500 h4-semibold"
                    />
                    <Text
                        content={showActiveOpportunities ? "Invest in curated, secured, high-yield opportunities" : "Take a glance at some of our past high-yield opportunities"}
                        className="text-gray-600 p5-regular text-center md:text-left"
                    />
                    <ViewOtherOpportunitiesButton buttonSize='extraSmall' buttonType="text" className='md:hidden mt-1' />
                </div>
                {/* Tablet and Desktop view */}
                {width > 767 && (<div className='space-y-4 w-full'>
                    <div className='flex justify-between items-center gap-x-4 w-full'>
                        <div className={`w-[240px] hidden ${!showActiveOpportunities ? 'xl:block' : ''}`} />
                        <div className="inline-flex items-start justify-start p-1 overflow-hidden bg-white border border-gray-300 rounded-3xl">
                            {tabs.map((tab) => {
                                const { id, value } = tab;
                                return (
                                    <li
                                        key={id}
                                        className={`list-none flex items-center cursor-pointer px-6 py-2 relative`}
                                        onClick={() => {
                                            activeButton(id)
                                            triggerGaEventsForOpportunitiesTabs(value)
                                        }}
                                    >
                                        <Text
                                            content={value}
                                            className={`relative z-10  ${id === filter
                                                ? 'text-basicWhite p4-medium'
                                                : 'text-primary-500 p4-semibold'
                                                }`}
                                        />
                                        <div className='relative z-10 ml-[10px] rounded-full flex w-5 h-5 items-center justify-center bg-primary-70'>
                                            <Text content={tab.count} className='p7-medium text-primary-500' />
                                        </div>
                                        {id === filter && (
                                            <motion.div
                                                className="absolute top-0 rounded-full bottom-0 right-0 left-0 -w-full h-full z-[5] bg-primary-500"
                                                layoutId="underline"
                                                variants={opacity}
                                                initial="hidden"
                                                animate="visible"
                                                exit="exit"
                                            />
                                        )}
                                    </li>
                                );
                            })}
                        </div>
                        <ViewOtherOpportunitiesButton buttonSize='small' buttonType='borderless' />
                    </div>
                    {
                        filter === opportunitiesFilterTypes.SDI && (
                            <motion.div key={"listing-tabs"} className={`inline-flex items-start ${showActiveOpportunities ? 'justify-start' : 'xl:justify-center w-full'} overflow-hidden`}>
                                {regulationTabs.map((tab) => {
                                    const { id, value } = tab;
                                    const isSelected = isUnlistedSDI && id === 'rbi' || !isUnlistedSDI && id === 'sebi';
                                    return (
                                        <li
                                            key={id}
                                            className={`list-none cursor-pointer flex items-center py-[10px] pr-[10px] pl-[14px]
                                                ${isSelected ? 'border-b-2 border-primary-500' : 'border-none'}`}
                                            onClick={tab.onClick}
                                        >
                                            <Text
                                                content={value}
                                                className={`relative z-10  ${isSelected
                                                    ? 'text-primary-500 p4-regular'
                                                    : ' text-gray-500 p4-medium'
                                                    }`}
                                            />
                                            <div className='relative z-10 ml-[10px] rounded-full flex w-5 h-5 items-center justify-center bg-primary-70'>
                                                <Text content={tab.count} className='p7-medium text-primary-500' />
                                            </div>
                                        </li>
                                    );
                                })}
                            </motion.div>
                        )
                    }
                </div>)}
                {/* Mobile view */}
                {width < 768 && (
                    <div className='w-full space-y-4'>
                        <div className="relative w-full inline-flex items-center justify-around overflow-hidden bg-white border-b-2 border-gray-200">
                            {tabs.map((tab) => {
                                const { id, value } = tab;
                                return (
                                    <li
                                        key={id}
                                        className={`list-none flex items-center cursor-pointer px-6 py-[10px] 
                                            ${id === filter
                                                ? 'text-primary-500 p4-medium border-b-2 border-primary-500'
                                                : 'text-gray-500 p4-regular'
                                            }`}
                                        onClick={() => {
                                            activeButton(id);
                                            triggerGaEventsForOpportunitiesTabs(value);
                                        }}
                                    >
                                        <Text
                                            content={value}
                                        />
                                        <div className='relative z-10 ml-[10px] rounded-full flex w-5 h-5 items-center justify-center bg-primary-70'>
                                            <Text content={tab.count} className='p7-medium text-primary-500' />
                                        </div>
                                    </li>
                                );
                            })}
                        </div>
                        {
                            filter === opportunitiesFilterTypes.SDI && (
                                <div key={"listing-tabs-mobile"} className="px-4 gap-x-3 w-full inline-flex items-start justify-center overflow-hidden">
                                    {regulationTabs.map((tab) => {
                                        const { id, value } = tab;
                                        const isSelected = isUnlistedSDI && id === 'rbi' || !isUnlistedSDI && id === 'sebi';
                                        return (
                                            <li
                                                key={id}
                                                className={`list-none cursor-pointer flex items-center py-2 pr-2 pl-3 gap-x-2 rounded-md border ${isSelected ? 'bg-primary-50 border-primary-70' : 'bg-basicWhite border-gray-200'}`}
                                                onClick={tab.onClick}
                                            >
                                                <Text
                                                    content={value}
                                                    className={`relative z-10  ${isSelected
                                                        ? ' text-primary-500 p5-medium'
                                                        : 'text-gray-500 p5-regular'
                                                        }`}
                                                />
                                                <div className='relative rounded-full flex w-5 h-5 items-center justify-center bg-primary-70'>
                                                    <Text content={tab.count} className='p7-medium text-primary-500' />
                                                </div>
                                            </li>
                                        );
                                    })}
                                </div>
                            )
                        }
                    </div>)}
                {/* {descriptionText && showActiveOpportunities
                    ? (<Text content={descriptionText} className="p5-regular md:p4-regular whitespace-pre-line text-gray-500 md:mt-2 px-4 md:px-0" />)
                    : null} */}
            </div >
        </AnimatePresence>
    );
};

export default OpportunityOverview;
