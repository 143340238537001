import { ChevronDownIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { Avatar, Button, Image, LazyImage, Link, Text } from '@atoms/index';
import { useRouter } from 'next/router';
import useNameAvatar from 'hooks/useNameAvatar';

const HamburgerMenuItem = ({
    menuItem,
    isUser,
    logout,
    closeMenu,
    showRMDetails,
    rmDetails,
    isLoggedIn,
    handleHeaderItemsClick = () => {}
}) => {
    const { name, submenu, link, gtmCustomTrigger, suffixIcon } = menuItem;
    const { avatar } = useNameAvatar(name);
    const router = useRouter();

    const renderRMSubMenu = (rmDetails) => {
        return (
            <>
                <div className="">
                    <div
                        className={`flex h-10 justify-around items-center mt-4 text-primary-500 `}
                    >
                        <button
                            className={`hover:bg-gray-200 flex-1 h-full bg-gray-100 rounded-md font-semibold text-base`}
                            onClick={async () => {
                                await router.push('/profile');
                                closeMenu();
                            }}
                            gtmtag={submenu[0]?.gtmtag}
                            id="myProileButton"
                        >
                            <Image
                                className={`inline h-5 pr-5`}
                                src={`/images/UserProfile.svg`}
                            />
                            Profile
                        </button>
                        <div className={`h-4 w-0.5 mx-3 bg-gray-300`} />
                        <button
                            className={`hover:bg-gray-200 flex-1 h-full rounded-md font-semibold text-base`}
                            onClick={logout}
                            id="logoutButton"
                            gtmtag={submenu[1]?.gtmtag}
                        >
                            <Image
                                className={`inline h-5 pr-5`}
                                src={`/images/Logout.svg`}
                            />
                            Logout
                        </button>
                    </div>
                    <div className="h-0.5 mt-4 w-full bg-gray-100" />
                    <Text
                        className={`py-4 text-gray-400 font-medium text-xs text-left`}
                        content="Your Relationship Manager"
                    />
                    <div className="flex items-center justify-start">
                        <Image
                            src={`${rmDetails.profileImageLink
                                ? rmDetails.profileImageLink
                                : '/images/user-solid.svg'
                                }`}
                            className={`w-10 h-10  ${!rmDetails.profileImageLink &&
                                'border-2 border-gray-400 bg-gray-400'
                                } rounded-full`}
                        />
                        <Text
                            className={`pl-4 text-gray-900 font-semibold text-base`}
                            content={rmDetails.name}
                        />
                    </div>
                    <div className="mt-4 text-left ">
                        <Text
                            className={`pr-6 text-gray-900 font-semibold text-sm inline`}
                            content="Email"
                        />
                        <a href={`mailto:${rmDetails.email}`}>
                            <Text
                                className={`hover:text-primary-500 hover:font-medium hover:-ml-px text-gray-900 text-sm inline`}
                                content={rmDetails.email}
                            />
                        </a>
                    </div>
                    <div className="mt-2 text-left ">
                        <Text
                            className={`text-gray-900 font-semibold text-sm inline`}
                            content="Phone"
                        />
                        <Text
                            className={`pl-4 text-gray-900 text-sm inline`}
                            content={rmDetails.mobile}
                        />
                    </div>
                    <a
                        href={`https://wa.me/${rmDetails.mobile}`}
                        target="_blank"
                    >
                        <Button
                            id="chatWithRM"
                            design="standard"
                            type="primary"
                            size="xl"
                            paddingClass="p-2"
                            className={`mt-4 w-full`}
                            icon="yes"
                            children={
                                <>
                                    <Image
                                        className="pr-2"
                                        src={`/images/Chat.svg`}
                                    />
                                    <Text
                                        content={`Chat With ${rmDetails.name.split(' ')[0]
                                            }`}
                                        className="inline text-sm font-semibold"
                                    />
                                </>
                            }
                        />
                    </a>

                    <div className="flex justify-start mt-5 text-left ">
                        <Image
                            className="mt-0.5 h-3 w-3"
                            src={`/images/InfoDark.svg`}
                        />
                        <Text
                            className={`pl-2.5 text-gray-500 text-xs`}
                            content={`Your relationship manager will be available on Whatsapp chat between 09:30 AM to 7:30 PM, Monday to Friday.`}
                        />
                    </div>
                </div>
            </>
        );
    };

    if (submenu) {
        const [isOpen, setIsOpen] = useState(false);
        return (
            <div>
                <div
                    className={`border-b-[0.5px] border-primary-100 flex justify-between py-5 text-primary-500 hover:text-primary-300 gap-x-2 ${isUser ? 'bg-basicWhite px-2' : ''
                        }`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isUser && avatar ? (
                        <div className="flex ml-1 max-w-[80%] gap-1 items-center">
                            <div className="w-8 h-8">
                                <Avatar icon={avatar} />
                            </div>
                            <Text
                                content={name}
                                className="ml-1 text-sm font-semibold"
                            />
                        </div>
                    ) : isUser ? (
                        <div className="inline-block ">
                            <div className="w-8 h-8 p-2 rounded-full bg-secondary-400">
                                <LazyImage
                                    src="/images/user-solid.svg"
                                    className="relative w-full h-full"
                                    alt="user"
                                />
                            </div>
                        </div>
                    ) : (
                        <Text
                            content={name}
                            className="text-base font-semibold"
                        />
                    )}
                    <ChevronDownIcon
                        className={`w-5 translate-all duration-100 ${isOpen ? 'rotate-180' : ''
                            }`}
                    />
                </div>
                <ul>
                    {isOpen &&
                        (showRMDetails
                            ? renderRMSubMenu(rmDetails)
                            : submenu
                                ?.filter((each) =>
                                    isLoggedIn ? each.showWhenLoggedIn : each
                                )
                                ?.map((item) => (
                                    <li
                                        className={`${item.name.toLowerCase() ===
                                            'profile'
                                            ? 'pl-4 pr-3 py-3'
                                            : 'p-3'
                                            }`}
                                    >
                                        <Link href={item.link}>
                                            <div
                                                className={`text-base ${item.link
                                                    ? 'text-gray-500 hover:text-primary-500'
                                                    : 'text-gray-300'
                                                    } font-semibold`}
                                                onClick={() => {
                                                    closeMenu();

                                                    if (
                                                        item.name.toLowerCase() ===
                                                        'logout' &&
                                                        isUser &&
                                                        logout
                                                    ) {
                                                        logout();
                                                    }
                                                }}
                                            >
                                                <Image
                                                    className={`inline h-5 pr-3`}
                                                    src={item.icon}
                                                />
                                                {item.name}
                                            </div>
                                        </Link>
                                    </li>
                                )))}
                </ul>
            </div>
        );
    }
    return (
        <Link
            href={link}
            onClick={() => {
                handleHeaderItemsClick(gtmCustomTrigger || null);
                closeMenu()
            }}
            className={`border-b-[0.5px] relative border-primary-100 flex justify-start items-center py-5 `}
        >
            <div className="flex gap-1 items-center">
                <Text
                    content={name}
                    className="text-base font-semibold text-primary-500"
                />
                {suffixIcon ? suffixIcon() : null}
            </div>
        </Link>
    );
};
export default HamburgerMenuItem;
