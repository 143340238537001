import React, { useState, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { v4 as uuidv4 } from 'uuid';
import { getSignedUrl } from '@services/aws.service';
import { UploadedImageCard } from '@molecules/index';
import { Text, Button, Image, Card } from '@atoms/index';
import Loader from '@molecules/Loader';
import { allowedFileUploads } from '@helpers/constants';
import { getAuth } from '@services/identity.service';
import { fileUploadHandler } from '@services/file-upload.service';

export const FileUploader = (props) => {
    const uploadImageCb = props.onUpload;
    const [crop, setCrop] = useState({
        unit: '%',
        width: 100,
        aspect: 16 / 9,
    });
    const [croppedImageUrl, setCroppedImageUrl] = useState();
    const [completedImageUrl, setCompletedImageUrl] = useState();
    const [src, setSrc] = useState();
    const [imageRef, setImageRef] = useState();
    const [showCropping, setShowCropping] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedImageType, setUploadedImageType] = useState('');
    const [auth] = useState(getAuth());
    let fileUrl;

    const uploadFile = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const type = file.type;
            const ext = file.type.split('/')[1];
            try {
                setIsUploading(true);
                const fileName = `${props.fileNamePrefix || 'file_' + uuidv4()
                    }.${ext}`;
                const key = encodeURIComponent(fileName);

                await fileUploadHandler(props.bucket, file, key);
                setIsUploading(false);
                const url = (await getSignedUrl(props.bucket, key)).entity;
                setUploadedImageType(type);
                uploadImageCb({
                    key,
                    type: ext,
                    url,
                    size: Math.round(file.size / 1024),
                });
            } catch (error) {
                throw error;
            }
        }
    };

    useEffect(() => {
        if (props.preCroppedImageUrl) {
            setCompletedImageUrl(props.preCroppedImageUrl);
        }
    }, [props.preCroppedImageUrl]);
    const onImageLoaded = (image) => {
        setImageRef(image);
    };
    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };
    const onCropChange = (crop, percentCrop) => {
        setCrop(crop);
    };
    const makeClientCrop = async (crop) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                imageRef,
                crop,
                'newFile.jpeg'
            );
            setCroppedImageUrl(croppedImageUrl);
        }
    };
    var getFileBlob = function (url, cb) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.addEventListener('load', function () {
            cb(xhr.response);
        });
        xhr.send();
    };
    const uploadImage = async () => {
        setIsUploading(true);
        const imageUrl =
            croppedImageUrl ||
            (await getCroppedImg(imageRef, crop, 'newFile.jpeg'));
        getFileBlob(imageUrl, async (blob) => {
            const fileName = `${props.fileNamePrefix || 'image_' + uuidv4()
                }.jpeg`;
            const key = encodeURIComponent(fileName);
            try {
                const file = new File(blob, key);

                await fileUploadHandler(props.bucket, file, key);
                setIsUploading(false);
                setShowCropping(false);
                setCompletedImageUrl(imageUrl);
                uploadImageCb(key);
            } catch (error) {
                throw error;
            }
        });
    };
    const doneCropping = () => {
        uploadImage();
        setCompletedImageUrl(croppedImageUrl);
    };
    const cancelCropping = () => {
        setSrc();
        setShowCropping(false);
    };
    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl);
            }, 'image/jpeg');
        });
    };

    const getImageData = () => {
        if (isUploading) {
            return (
                <div className="w-2/5 bg-gray-100 flex justify-center p-8 rounded-lg">
                    <Loader imageUrl="" content="Uploading" />
                </div>
            );
        }
        return props.isSingleUpload ? (
            <div className="w-full bg-gray-100 flex justify-center p-5 rounded-lg">
                <Image
                    className="w-3/5 h-60 object-scale-down"
                    src={props.defaultImage}
                />
            </div>
        ) : (
            <div className="">
                <Image
                    className="w-full max-w-full  max-h-40 object-scale-down"
                    src={props.defaultImage}
                />
            </div>
        );
    };

    return (
        <>
            <label htmlFor={'fileUpload' + props.id} className="cursor-pointer">
                <Card className="w-full animate__animated animate__fadeInUp animate__faster">
                    <div>
                        <label className="text-xs text-left">
                            {props.label}
                        </label>

                        {!completedImageUrl && (
                            <div
                                className={`flex ${props.isSingleUpload
                                    ? 'flex-col items-center'
                                    : 'xl:flex-row lg:justify-around   flex-col'
                                    } mt-2 bg-basicWhite bg-opacity-25 rounded-lg`}
                            >
                                {getImageData()}

                                <div
                                    className={`flex flex-col text-center justify-center ${!props.isSingleUpload
                                        ? 'mx-0 my-0 xl:mt-0 mt-2'
                                        : 'mx-6 my-5'
                                        }`}
                                >
                                    <Text
                                        content={
                                            <div
                                                className={`flex justify-center items-center	`}
                                            >
                                                <div>
                                                    <Image
                                                        src={
                                                            '/images/investors/kyc/download-icon.svg'
                                                        }
                                                    />
                                                </div>
                                                <Text
                                                    className={`${!props.isSingleUpload
                                                        ? 'lg:text-xs text-sm '
                                                        : ''
                                                        } text-yellow-500 ml-2`}
                                                    content="Click here to Upload"
                                                />
                                            </div>
                                        }
                                    />
                                    <Text
                                        content=" JPEG, JPG, PNG, PDF, TIFF"
                                        className={`${!props.isSingleUpload
                                            ? 'lg:text-xs mt-1 text-sm'
                                            : ''
                                            }p-1  text-gray-400`}
                                    />
                                    <Text
                                        content="Max File Size: 4MB"
                                        className={`${!props.isSingleUpload
                                            ? 'lg:text-xs text-sm'
                                            : ''
                                            } p-1  text-gray-400`}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {showCropping && (
                        <div
                            className="fixed inset-0 z-10 overflow-y-auto"
                            aria-labelledby="modal-title"
                            role="dialog"
                            aria-modal="true"
                        >
                            <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                                {' '}
                                <div
                                    className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
                                    aria-hidden="true"
                                ></div>{' '}
                                <span
                                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>{' '}
                                <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-basicWhite rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className="px-4 pt-5 pb-4 bg-basicWhite sm:p-6 sm:pb-4">
                                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                            <ReactCrop
                                                src={src}
                                                crop={crop}
                                                ruleOfThirds
                                                onImageLoaded={onImageLoaded}
                                                onComplete={onCropComplete}
                                                onChange={onCropChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2 px-5 pb-5 ">
                                        <Button
                                            id="doneFileUploadButton"
                                            onClick={() => doneCropping()}
                                            design="standard"
                                            type="primary"
                                            className="justify-center w-full mt-6"
                                            children={
                                                <Text
                                                    content="Done"
                                                    size="base"
                                                    weight="bold"
                                                    className="text-center text-basicWhite md:text-lg"
                                                />
                                            }
                                        />
                                        <Button
                                            id="cancelFileUploadButton"
                                            onClick={() => cancelCropping()}
                                            design="standard"
                                            type="ghost"
                                            className="justify-center w-full mt-6"
                                            children={
                                                <Text
                                                    content="Cancel"
                                                    size="base"
                                                    weight="bold"
                                                    className="text-center md:text-lg"
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {completedImageUrl && (
                        <UploadedImageCard
                            label={props.imgName}
                            imageSrc={completedImageUrl}
                            uploadFile={(event) => uploadFile(event)}
                            isSingleUpload={props.isSingleUpload}
                            isUploading={isUploading}
                            uploadedImageType={uploadedImageType}
                        />
                    )}
                    {props.error && (
                        <label className="items-center w-24 h-24 p-2">
                            {props.error.message}
                        </label>
                    )}
                </Card>
            </label>
            <input
                type="file"
                id={'fileUpload' + props.id}
                name="cameraInput"
                onChange={uploadFile}
                className="hidden btn btn-default spacer-top"
                accept={allowedFileUploads}
            />
        </>
    );
};
export default FileUploader;
