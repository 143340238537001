import { Modal, Text, Button } from '@atoms/index';

const ConfirmationModel = ({
    onClose,
    closeAlert,
    text,
    confirmButtonText,
    closeButtonText,
}) => {
    return (
        <Modal
            show={true}
            onClose={onClose}
            hideTitle="true"
            width="md:w-11/12 xl:w-2/5 2xl:w-140"
            height="48"
            children={
                <div className="py-4">
                    <div className="w-11/12">
                        <Text
                            content={text}
                            className="inline xl:text-2xl text-gray-800"
                            size="xl"
                        />
                        <div className="flex justify-center">
                            <div className="grid w-full grid-cols-2 gap-2 pt-6 xl:w-3/4">
                                <Button
                                    onClick={closeAlert}
                                    design="standard"
                                    type="ghost"
                                    className="justify-center w-full !border-secondary-500 hover:bg-secondary-500"
                                    children={
                                        <Text
                                            content={confirmButtonText}
                                            size="base"
                                            weight="bold"
                                            className="text-center md:text-lg text-basicBlack"
                                        />
                                    }
                                />
                                <Button
                                    onClick={() => onClose()}
                                    design="standard"
                                    type="ghost"
                                    className="justify-center w-full !border-secondary-500 hover:bg-secondary-500"
                                    children={
                                        <Text
                                            content={closeButtonText}
                                            size="base"
                                            weight="bold"
                                            className="text-center md:text-lg text-basicBlack"
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
    );
};

export default ConfirmationModel;
