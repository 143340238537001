import { Text, Link, Image } from '@atoms/index';
import Submenu from './Submenu';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';

const HeaderMenuItem = ({ menuItem, selected, setSelected, isLoggedIn, handleHeaderItemsClick }) => {
    const routePath = useRouter().pathname;

    return (
        <>
            {menuItem?.submenu?.filter((each) =>
                isLoggedIn ? each.showWhenLoggedIn : !each.showWhenLoggedIn
            ) ? (
                <div
                    className="relative"
                    onClick={() => setSelected(!selected)}
                >
                    <div
                        className={` ${
                            menuItem.selectOn &&
                            menuItem?.selectOn.includes(routePath)
                                ? 'text-primary-500 font-bold -mb-1'
                                : 'text-gray-500 hover:text-primary-500 font-semibold'
                        } cursor-pointer flex flex-grow items-center justify-center p3-semibold`}
                    >
                        <Text
                            content={menuItem.name}
                            className=" max-w-min xl:max-w-max whitespace-nowrap p3-semibold"
                        />
                        {menuItem.submenu && (
                            <ChevronDownIcon
                                className={`h-6 w-6 transform-all duration-100 ${
                                    selected ? 'rotate-180' : ''
                                }`}
                            />
                        )}
                    </div>
                    <div
                        className={`duration-100 translate-all ${
                            selected
                                ? 'translate-y-0'
                                : 'invisible translate-y-2'
                        }`}
                    >
                        <Submenu
                            menuItems={menuItem?.submenu?.filter((each) =>
                                isLoggedIn ? each.showWhenLoggedIn : each
                            )}
                        />
                    </div>
                </div>
            ) : (
                <Link href={menuItem.link}>
                    <div
                        onClick={() => {
                            setSelected(!selected)
                            handleHeaderItemsClick(menuItem?.gtmCustomTrigger || null)
                        }}
                        className={`py-1 text-base ${
                            menuItem.selectOn &&
                            menuItem?.selectOn.includes(routePath)
                                ? 'text-primary-500 font-bold -mb-1'
                                : 'text-gray-500 hover:text-primary-500 font-semibold'
                        } cursor-pointer whitespace-nowrap flex items-center p3-semibold`}
                    >
                        <div className="flex gap-1 items-center">
                            {menuItem?.name}
                            {menuItem?.suffixIcon ? menuItem.suffixIcon() : null}
                        </div>
                    </div>
                </Link>
            )}
        </>
    );
};

export default HeaderMenuItem;
