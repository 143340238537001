import useMultiCheckboxOrRadioBox from 'hooks/useMultiCheckboxOrRadioBox';
import FilterHead from './FilterHead';

const MultiCheckbox = ({
    name,
    label,
    options,
    className,
    // optional
    labelClassName = '',
    inputWrapperClass = '',
    canReset = false,
    canCollapsible = false,
    defaultSelected = [],
    onSelect = (val) => {},
}) => {
    const { selected, isVisible, setIsVisible, onChange, onReset } =
        useMultiCheckboxOrRadioBox({
            type: 'checkbox',
            options,
            defaultSelected,
            canCollapsible,
            onSelect,
        });

    return (
        <div className={className}>
            <div className="flex flex-col gap-2 w-auto h-30 font-montserrat">
                <FilterHead
                    label={label}
                    isVisible={isVisible}
                    labelClassName={labelClassName}
                    canReset={false}
                    canCollapsible={canCollapsible}
                    onReset={onReset}
                    setIsVisible={setIsVisible}
                />
                <div
                    className={`relative ${
                        isVisible ? 'flex' : 'hidden'
                    } flex-row gap-2 w-auto flex-wrap justify-center xl:justify-around`}
                >
                    {options.map((option, i) => {
                        return (
                            <div
                                className={`flex items-center gap-2 px-2 py-1 rounded-md font-montserrat ${inputWrapperClass}`}
                                key={i}
                            >
                                <input
                                    type="checkbox"
                                    value={option.value}
                                    id={option.label}
                                    checked={
                                        option?.value !== 'all'
                                            ? selected.includes(option.value)
                                            : selected.length ===
                                              options.length - 1
                                    }
                                    onChange={(e) => onChange(e, option)}
                                />
                                <label htmlFor={option.label}>
                                    {option.label}
                                </label>
                            </div>
                        );
                    })}

                    {/* hidden input */}
                    <input
                        type="hidden"
                        name={name}
                        value={selected.map((item) => item).join(',')}
                    />
                </div>
            </div>
        </div>
    );
};

export default MultiCheckbox;
