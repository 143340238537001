import { Text } from '@atoms/index';
import { useEffect, useRef, useState } from 'react';
import DesktopNavbar from '@components/Navbar/Desktop/Navbar';
import MobileNavbar from '@components/Navbar/Mobile/Navbar';
import useEventListener from '@hooks/useEventListner';
import { positionChatBotWithAuth, positionChatBotWithOutAuth, triggerCustomGaEvent } from '@helpers/miscelleanous';
import useAuthStore from '@store/useAuthStore';
import { useRouter } from 'next/router';
import { useWindowSize } from '@uidotdev/usehooks';
import Button from '@ui/atoms/design-system/Button';
import useWindowDimensions from 'hooks/useWindowDimensions';


const LoginButton = ({ onClick, className = '' }) => {
    const { width } = useWindowDimensions();
    return (
        <Button
            buttonType="primary"
            onClick={onClick}
            buttonText={`Login/Sign Up`}
            buttonSize={`${width < 768 ? 'extrasmall' : 'small'}`}
            className='!rounded-full'
        />
    );
};

function NavHeader({
    hideHeaderOnScroll = false,
    translucent = false,
    homePage = false,
}) {
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const lastScrollPosition = useRef(0);
    const { isLogged } = useAuthStore();
    const router = useRouter();
    const { width } = useWindowSize();

    const onScroll = () => {
        const currentScrollPos = window.pageYOffset;
        setIsHeaderVisible(
            lastScrollPosition.current > currentScrollPos ||
            currentScrollPos <= 0
        );

        lastScrollPosition.current = currentScrollPos;
    };

    const [lastScrollPos, setLastScrollPos] = useState(0);
    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const visible =
            lastScrollPos > currentScrollPos || currentScrollPos === 0;
        setLastScrollPos(currentScrollPos);
    };

    useEventListener('scroll', handleScroll);
    hideHeaderOnScroll && useEventListener('scroll', onScroll);

    const handleHeaderItemsClick = (gtmTriggerName) => {
        if (gtmTriggerName) {
            triggerCustomGaEvent(gtmTriggerName);
        };
    };

    useEffect(() => {
        if (isLogged) {
            positionChatBotWithAuth(width);
        } else {
            positionChatBotWithOutAuth();
        }
    }, [isLogged, router, width]);

    return (
        <div
            id="sticky-header"
            className={`fixed ${!isHeaderVisible ? 'slide-up' : ''
                } flex flex-col items-center justify-center !m-0 h-16  navbar-sticky bg-basicWhite ${translucent ? 'bg-opacity-25' : ''
                } left-0 z-100 w-full`}
        >
            {/* Mobile and Tablet view */}
            <MobileNavbar
                LoginButton={LoginButton}
                homePage={homePage}
                handleHeaderItemsClick={handleHeaderItemsClick}
            />
            {/* desktop view */}
            <DesktopNavbar
                LoginButton={LoginButton}
                homePage={homePage}
                handleHeaderItemsClick={handleHeaderItemsClick}
            />
        </div>
    );
}

export default NavHeader;
