export const SUPPORT_EMAIL_IFA = 'partners@jiraaf.com';
export const SUPPORT_EMAIL = 'help@jiraaf.com';
export const GRIEVANCES_EMAIL = 'grievance@jiraaf.com';
export const ESCALATION_EMAIL = 'escalation@jiraaf.com';
export const SUPPORT_PHONE = '+91 8953265326';
export const COMPLIANCE_EMAIL = 'compliance.officer@jiraaf.com';
export const SEBI_REGISTRATION_NUMBER = 'INZ000315538';
export const COMPLIANCE_OFFICER_NAME = 'Ms. Richa Mishra';
export const TRADING_NAME = 'Jiraaf Platform Private Limited';
export const TRADING_CIN_NUMBER = 'U65120KA2021PTC149273';
export const SEBI_OFFICE_ADDRESS = 'https://www.sebi.gov.in/contact-us.html';
export const SEBI_ON_SCORES_ADDRESS =
    'https://scores.gov.in/scores/Welcome.html';
export const COMPLIANCE_OFFICER_NUMBER = '+91 9611312885';
export const NSE_INVESTOR_SERVICE_CENTER =
    'https://www.nseindia.com/contact/investor-services-centre';
export const ODR_WEF_DATE = 'August 16, 2023';
export const ODR_PORTAL = 'https://smartodr.in/';
export const SEBI_TOLL_FREE_NUMBER = '1800 22 7575';
export const SEBI_TOLL_FREE_NUMBER2 = '1800 266 7575';
export const NSE_MEMBER_ID = '90355';
export const BSE_MEMBER_ID = '6835 ';
export const INVESTOR_SEBI_LINK = 'https://investor.sebi.gov.in/';
export const SEBI_SCORE_LINK = 'https://scores.gov.in/scores/Welcome.html';
export const SMART_ODR_PORTAL = 'https://smartodr.in/login';