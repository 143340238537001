import { useEffect, useState } from 'react';
import useComponentVisible from './useComponentVisible';

const useMultiSelectSearch = ({
    defaultSelected = [],
    options = [],
    isDebounseSearch = false,
    canCollapsible,
    defaultOpen = true,
    onSelect = (v) => {},
    onSearchDebounsed = (v, cb) => {},
}) => {
    const { ref, isComponentVisible, setIsComponentVisible } =
        useComponentVisible(false);
    const [selected, setSelected] = useState(defaultSelected);
    const [dropdownOptions, setDropdownOptions] = useState(options);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(defaultOpen);
    const [value, setValue] = useState('');

    const isActive = (value) => {
        return selected.find((item) => item === value) !== undefined;
    };

    const onChange = (e, option) => {
        e.preventDefault();
        let values = [];
        if (!isActive(option.value)) {
            values = [...selected, option.value];
            setValue('');
            if (isDebounseSearch) setDropdownOptions([]);
            setIsComponentVisible(false);
        } else {
            values = selected;
        }
        setSelected(values);
        onSelect(values);
    };

    const onChangeDebounse = (value) => {
        if (value.length > 2) {
            setIsLoading(true);
            setIsComponentVisible(true);
            onSearchDebounsed(value, (data) => {
                setDropdownOptions(data);
                setIsLoading(false);
            });
        } else {
            setIsComponentVisible(true);
            setDropdownOptions(options);
            setDropdownOptions([]);
        }
    };

    // onReset
    const onReset = () => {
        setSelected([]);
        onSelect([]);
    };

    // on Input change
    const onInputChange = (e) => {
        if (isDebounseSearch) {
            onChangeDebounse(e.target.value);
        } else {
            setDropdownOptions(
                options.filter((item) =>
                    item.label
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );

            setIsComponentVisible(true);
        }

        setValue(e.target.value);
    };

    const onRemoveSelectedItem = (e, index) => {
        e.preventDefault();
        const afterRemoveSelected = selected.filter(
            (item) => item !== selected[index]
        );
        setSelected(afterRemoveSelected);
        onSelect(afterRemoveSelected);
    };

    // on change of default select, selected value will be update
    useEffect(() => {
        setSelected(defaultSelected);
    }, [defaultSelected]);

    useEffect(() => {
        if (selected?.length) {
            onSelect(selected);
            setIsVisible(true);
        }
    }, [selected]);

    useEffect(() => {
        setIsVisible(defaultOpen);
    }, [defaultOpen]);

    return {
        ref,
        isComponentVisible,
        selected,
        dropdownOptions,
        isLoading,
        isVisible,
        value,
        setValue,
        onInputChange,
        onReset,
        setIsVisible,
        isActive,
        onChange,
        onChangeDebounse,
        setIsComponentVisible,
        setSelected,
        setDropdownOptions,
        onRemoveSelectedItem,
    };
};

export default useMultiSelectSearch;
