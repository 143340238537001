import Input from '../atoms/Input';

const OtpBox = ({ register }) => {
    return (
        <div className="text-center">
            <Input
                inputMode="numeric"
                type="text"
                id="otp"
                className="w-1/2 mx-auto mt-10 text-center bg-gray-100 xl:w-1/4"
                register={register}
                placeholder="X - X - X - X "
                maxlength="4"
                autoComplete="off"
            />
        </div>
    );
};

export default OtpBox;
