const InputWithIcon = ({
    id,
    register,
    type,
    placeholder,
    label,
    labelClass,
    error,
    onClick,
    onPaste,
    onBlur,
    width,
    height,
    errorClass,
    readOnly = false,
    icon, // New prop for the icon component
    ...inputProps
}) => {
    return (
        <div className="relative">
            {label && (
                <label
                    className={`block w-full text-left text-trueGray-900 ${labelClass}`}
                >
                    {label}
                </label>
            )}
            <div className="relative flex">
                <input
                    {...register(id)}
                    id={id}
                    {...inputProps}
                    type={type}
                    onClick={onClick}
                    onPaste={onPaste}
                    onBlur={onBlur}
                    className={`${
                        error ? 'border-red-600' : 'border-gray-300'
                    } ${inputProps.border ? inputProps.border : 'border-1'} ${
                        width ? width : 'w-full'
                    } ${
                        height ? height : 'h-12'
                    } px-4 py-3 rounded-lg mt-2 box-border ${
                        inputProps.className
                    }`}
                    placeholder={placeholder}
                    readOnly={readOnly}
                />
                {icon && (
                    <div className="absolute transform -translate-y-1/2 right-3 top-1/2">
                        {icon}
                    </div>
                )}
            </div>
            {error && (
                <div className={`text-red-600 text-left ${errorClass}`}>
                    {error.message}
                </div>
            )}
        </div>
    );
};

export default InputWithIcon;
