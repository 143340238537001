import Link from '@ui/atoms/Link';
import Text from '@ui/atoms/Text';
import { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid';

const SubmenuItem = ({ name, link, gtmTag = '' }) => {
    const [hover, setHover] = useState(false);
    return (
        <Link href={link} key={name} gtmTag={gtmTag}>
            <div
                className={`px-4 py-3 font-semibold ${link
                    ? 'hover:bg-gray-50 text-gray-500 hover:text-primary-500'
                    : 'text-gray-200'
                    } flex items-center justify-between gap-2 cursor-pointer rounded-xl`}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <Text content={name} className="text-base whitespace-nowrap" />
                <ChevronRightIcon
                    className={`w-6 h-6 ${hover && link ? '' : 'invisible'}`}
                />
            </div>
        </Link>
    );
};

const Submenu = ({ menuItems }) => {
    return (
        <div className="absolute top-4 left-1/2 translate-x-[-50%] bg-basicWhite p-3 rounded-xl flex flex-col gap-1 border">
            {menuItems.map((item, index) => (
                <SubmenuItem
                    name={item.name}
                    link={item.link}
                    key={`${item.name}-${index}`}
                    gtmTag={item?.gtmtag}
                />
            ))}
        </div>
    );
};

export default Submenu;
