import { Image, Link, Text } from '@atoms/index';
import AuthContext from '@context/auth';
import {
    AdvisoryForInvestors,
    supportEmail,
    VoluntaryFreezingPolicy,
} from '@helpers/constants';
import { bondDirectoryGaEventsName, footerGaEventsName } from '@helpers/ga-events-name-enum';
import { mailToGmail, triggerCustomGaEvent } from '@helpers/miscelleanous';
import { ChevronDownIcon } from '@heroicons/react/solid';
import useAuthStore from '@store/useAuthStore';
import useWindowDimensions from '@ui/hooks/useWindowDimensions';
import {
    CB_PRODUCT_ROUTE,
    GSEC_SDL_PRODUCT_ROUTE,
    SDIs_PRODUCT_ROUTE,
    SGB_PRODUCT_ROUTE,
    TB_PRODUCT_ROUTE,
} from '@ui/lib/constants/routes';
import {
    BSE_MEMBER_ID,
    COMPLIANCE_EMAIL,
    COMPLIANCE_OFFICER_NAME,
    COMPLIANCE_OFFICER_NUMBER,
    GRIEVANCES_EMAIL,
    INVESTOR_SEBI_LINK,
    NSE_MEMBER_ID,
    SEBI_OFFICE_ADDRESS,
    SEBI_REGISTRATION_NUMBER,
    SEBI_SCORE_LINK,
    SMART_ODR_PORTAL,
    TRADING_CIN_NUMBER,
    TRADING_NAME,
} from '@ui/lib/helpers';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useState, useEffect } from 'react';
import { getOpenConfigurations } from '@services/configuration.service';

const Footer = ({ className, entityType, showFooter = true, ...property }) => {
    const { width } = useWindowDimensions();
    const { isLogged } = useAuthStore();
    const [showMore, setShowMore] = useState(false);
    const copyRightYear = new Date().getFullYear();
    const { bondFeatureFlag } = useContext(AuthContext);

    const [expanded, setExpanded] = useState(-1);
    const [vernacularLink, setVernacularLink] = useState(false);
    useEffect(() => {
        const fetchConfigurationData = async () => {
            try {
                const configurationsResponse = await getOpenConfigurations(
                    'RDDinVernacular'
                );
                if (configurationsResponse?.entity) {
                    setVernacularLink(configurationsResponse?.entity[0]?.value);
                }
            } catch (error) {
                console.error('Error fetching configuration details:', error);
            }
        };
        fetchConfigurationData();
    }, []);
    const FooterData = [
        {
            title: 'About Us',
            subMenu: [
                {
                    title: 'Our Company',
                    link: '/about-us#',
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-our-company',
                },
                {
                    title: 'Values',
                    link: '/about-us#values',
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-values',
                },
                {
                    title: 'Investors',
                    link: '/about-us#investors',
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-investors',
                },
                // {
                //     title: 'The Team',
                //     link: '/team',
                //     onlyLogin: false,
                //    gtmTag: 'gtm-click-footer-teams',
                // },
                // // {
                // //     title: 'Careers',
                // //     link: '/careers',
                // //     onlyLogin: false,
                // //     gtmTag: 'gtm-click-footer-careers',
                // },
            ],
        },
        {
            title: 'Products',
            subMenu: [
                {
                    title: 'Listed Corporate Bonds',
                    link: CB_PRODUCT_ROUTE,
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-corporate-bonds',
                },
                {
                    title: 'Sovereign Gold Bonds',
                    link: SGB_PRODUCT_ROUTE,
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-sgb',
                },
                {
                    title: 'Treasury Bills',
                    link: TB_PRODUCT_ROUTE,
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-t-bills',
                },
                {
                    title: 'G-Sec & SDLs',
                    link: GSEC_SDL_PRODUCT_ROUTE,
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-gsec-sdl',
                },
                {
                    title: 'Securitized Debt Instruments',
                    link: SDIs_PRODUCT_ROUTE,
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-sdis',
                },
            ],
        },
        {
            title: 'Knowledge Centre',
            subMenu: [
                {
                    title: 'Bond Fundamentals',
                    link: '/bondfundamentals',
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-bond-fundamentals',
                },
                {
                    title: 'Credit Assessment Process',
                    link: '/credit-evaluation-process',
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-credit-assessment-process',
                },
                {
                    title: 'Blogs',
                    link: '/blogs',
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-blogs',
                },
                {
                    title: 'Media',
                    link: '/media',
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-media',
                },
                {
                    title: 'FAQs',
                    link: '/faq',
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-faqs',
                },
                {
                    title: 'Glossary',
                    link: '/glossary',
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-glossary',
                },
            ],
        },
        {
            title: 'Quick Links',
            subMenu: [
                {
                    title: 'Opportunities',
                    link: isLogged ? '/opportunities' : '/app/signup',
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-opportunities',
                },
                bondFeatureFlag && {
                    title: 'Bond Directory',
                    link: '/bond-directory',
                    onlyLogin: false,
                    gtmCustomTrigger: bondDirectoryGaEventsName.CLICKED_BOND_DIRECTORY_FOOTER,
                },
                // {
                //     title: 'Risk Disclosure',
                //     link: '/risk-disclosure',
                //     onlyLogin: false,
                // },
                {
                    title: 'Contact Us',
                    link: '/contact-us',
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-contact-us',
                },
                {
                    title: 'Account Opening Process',
                    link: '/documents/Account_Opening.pdf',
                    onlyLogin: false,
                    gtmTag: 'gtm-click-footer-account-opening-form',
                    target: '_blank',
                },
                ...(vernacularLink
                    ? [
                        {
                            title: 'RDD in Vernacular',
                            link: vernacularLink,
                            onlyLogin: false,
                            gtmTag: 'gtm-click-footer-RDD-in-Vernacular',
                            target: '_blank',
                        },
                    ]
                    : []),
                // {
                //     title: 'Refer & Earn',
                //     link: '/referral-program',
                //     onlyLogin: false,
                //     gtmCustomTrigger: footerGaEventsName.REFER_AND_EARN_FOOTER
                // },
            ].filter(Boolean),
        },
    ];

    const BottomLinks = [
        {
            name: 'Advisory for Investors',
            href: AdvisoryForInvestors,
            gtmTag: 'gtm-click-advisory-for-investors',
            textStyle: 'p5-bold text-secondary-500',
        },
        {
            name: 'Voluntary Freezing Policy',
            href: VoluntaryFreezingPolicy,
            gtmTag: 'gtm-click-voluntary-freezing-policy',
            textStyle: '',
        },
        {
            name: 'Privacy Policy',
            href: '/privacy-policy',
            gtmTag: 'gtm-click-footer-privacy-policy',
            textStyle: '',
        },
        {
            name: 'Terms of Use',
            href: '/terms-and-condition',
            gtmTag: 'gtm-click-footer-terms-of-use',
            textStyle: '',
        },
        {
            name: 'Disclaimer',
            href: '/disclaimer',
            gtmTag: 'gtm-click-footer-disclaimer',
            textStyle: '',
        },
        // TODO Commented for now
        // {
        //     name: 'Grievance Redressal',
        //     href: '/grievance-redressal',
        //     gtmTag: 'gtm-click-footer-grievance-redressal'
        // },
        {
            name: 'Investor Charter',
            href: '/investor-charter',
            gtmTag: 'gtm-click-footer-investor-charter',
            textStyle: '',
        },
    ];

    const Links = () => (
        <div className={`flex justify-center`}>
            <div
                className={`flex flex-wrap gap-y-3 p5-regular justify-center text-gray-100 gap-x-6 ${isLogged ? 'px-1' : 'px-1 xl:px-0'
                    }`}
            >
                {BottomLinks.map((item, index) => (
                    <Link
                        href={item.href}
                        key={index}
                        gtmTag={item?.gtmTag}
                        className={'flex-1'}
                        target={'_blank'}
                    >
                        <Text
                            content={item.name}
                            htmlTag="p"
                            className={`text-center whitespace-nowrap ${item.textStyle}`}
                        />
                    </Link>
                ))}
            </div>
        </div>
    );

    const handleFooterItemsOnClick = (gaTriggerName) => {
        if (gaTriggerName) {
            triggerCustomGaEvent(gaTriggerName);
        }
    };

    return (
        <div
            className={`bg-primary-800 z-50 w-full ${className} ${showFooter ? 'inline-block' : 'hidden'
                }`}
        >
            <div className="mx-auto max-w-screen-maxScreen">
                <div
                    className={`${!isLogged
                        ? 'py-8 px-4 md:py-10 md:px-6 xl:pt-14 xl:pb-10 xl:px-10 maxScreen:px-16'
                        : 'py-6 px-4 md:px-6 xl:pb-6 xl:pt-8 xl:px-10'
                        } space-y-6`}
                >
                    {/* Top */}
                    {!isLogged && (
                        <div
                            className={`md:pb-6 md:border-b-[0.5px] border-gray-300 flex flex-col xl:flex-row xl:gap-x-[72px] maxScreen:gap-x-28 gap-y-8 md:gap-y-6`}
                        >
                            {/* Jiraaf Logo container */}
                            <div className={`flex flex-col gap-y-6 basis-1/3`}>
                                <div
                                    className={`flex items-center justify-between`}
                                >
                                    <Image
                                        src={'/images/jiraaf-logo-full.svg'}
                                        alt="jiraaf logo icon"
                                        className="w-[118px] h-[32px] xl:w-[147px] xl:h-[40px]"
                                    />
                                    <div
                                        className={`items-center gap-x-4 flex`}
                                    >
                                        <Link
                                            href="https://www.linkedin.com/company/jiraafofficial"
                                            target="_blank"
                                            children={
                                                <Image
                                                    className="w-6 h-6"
                                                    src={'/images/linkedin.svg'}
                                                    alt="jiraaf linkedin profile link icon"
                                                />
                                            }
                                            gtmTag="gtm-click-footer-linkedin"
                                        />
                                        <Link
                                            href="https://www.youtube.com/@jiraaf_official"
                                            target="_blank"
                                            children={
                                                <Image
                                                    className="w-6 h-6"
                                                    src={'/images/youtube.svg'}
                                                    alt="jiraaf youtube channel link icon"
                                                />
                                            }
                                            gtmTag="gtm-click-footer-youtube"
                                        />
                                        <Link
                                            href="https://www.instagram.com/jiraaf_official/"
                                            target="_blank"
                                            children={
                                                <Image
                                                    className="w-6 h-6"
                                                    src={
                                                        '/images/instagram.svg'
                                                    }
                                                    alt="jiraaf instagram profile icon"
                                                />
                                            }
                                            gtmTag="gtm-click-footer-instagram"
                                        />

                                        <Link
                                            href="https://www.twitter.com/Jiraaf_Official"
                                            target="_blank"
                                            children={
                                                <Image
                                                    className="w-6 h-6"
                                                    src={'/images/twitter.svg'}
                                                    alt="jiraaf twitter profile link icon"
                                                />
                                            }
                                            gtmTag="gtm-click-footer-twitter"
                                        />

                                        <Link
                                            href="https://www.facebook.com/Jiraaf_Official"
                                            target="_blank"
                                            children={
                                                <Image
                                                    className="w-6 h-6"
                                                    src={'/images/facebook.svg'}
                                                    alt="jiraaf facebook profile link icon"
                                                />
                                            }
                                            gtmTag="gtm-click-footer-facebook"
                                        />
                                    </div>
                                </div>
                                <div className="flex items-start">
                                    <Image
                                        className="w-6 h-6 mr-2"
                                        src="/images/location-blue.svg"
                                    />
                                    <Text
                                        htmlTag="p"
                                        className="p5-regular text-primary-100"
                                        content={
                                            'Registered Address : No. 371, St.Johns Hospital Road, Santoshpuram, Koramangala 3rd Block, Bangalore South, KA - 560034'
                                        }
                                    />
                                </div>
                            </div>

                            {/* Menu container*/}
                            <div
                                className={`flex flex-col md:flex-row md:justify-between basis-2/3
                                    `}
                            >
                                {FooterData.map((menu, index) => {
                                    return (
                                        <div
                                            className={`flex flex-col border-b-[0.5px] pb-2 mb-2 md:p-0 md:m-0 last:border-none md:border-none border-primary-300`}
                                            key={`${menu?.title}-${index}`}
                                        >
                                            <motion.header
                                                initial={
                                                    width >= 768 ? true : false
                                                }
                                                animate={{}}
                                                onClick={() =>
                                                    width < 768 &&
                                                    setExpanded(
                                                        index === expanded
                                                            ? -1
                                                            : index
                                                    )
                                                }
                                                className="flex items-center justify-between py-2 text-basicWhite md:p-0"
                                            >
                                                <Text
                                                    htmlTag="p"
                                                    className="h6-semibold"
                                                    content={menu?.title}
                                                />
                                                <ChevronDownIcon
                                                    className={`md:hidden w-4 h-4 transform transition-all ease-linear duration-150  ${index === expanded
                                                        ? 'rotate-180'
                                                        : 'rotate-0'
                                                        }`}
                                                />
                                            </motion.header>
                                            <AnimatePresence initial={false}>
                                                {(width >= 768 ||
                                                    index === expanded) && (
                                                        <motion.section
                                                            key="content"
                                                            className="mt-1 md:mt-5"
                                                            initial="collapsed"
                                                            animate="open"
                                                            exit="collapsed"
                                                            variants={{
                                                                open: {
                                                                    opacity: 1,
                                                                    height: 'auto',
                                                                    x: 0,
                                                                },
                                                                collapsed: {
                                                                    opacity: 0,
                                                                    height: 0,
                                                                    x: '-100vw',
                                                                },
                                                            }}
                                                            transition={{
                                                                duration: 0.5,
                                                                ease: [
                                                                    0.04, 0.62,
                                                                    0.23, 0.98,
                                                                ],
                                                            }}
                                                        >
                                                            <div className="flex flex-col md:gap-y-4">
                                                                {menu?.subMenu?.map(
                                                                    (
                                                                        items,
                                                                        index
                                                                    ) => (
                                                                        <Link
                                                                            href={
                                                                                items?.link
                                                                            }
                                                                            key={`${items?.title}-${index}`}
                                                                            gtmTag={
                                                                                items?.gtmTag
                                                                            }
                                                                            target={
                                                                                items?.target ||
                                                                                '_self'
                                                                            }
                                                                            onClick={() =>
                                                                                handleFooterItemsOnClick(
                                                                                    items?.gtmCustomTrigger ||
                                                                                    null
                                                                                )
                                                                            }
                                                                        >
                                                                            <motion.p
                                                                                variants={{
                                                                                    collapsed:
                                                                                    {
                                                                                        scale: 0.8,
                                                                                    },
                                                                                    open: {
                                                                                        scale: 1,
                                                                                    },
                                                                                }}
                                                                                transition={{
                                                                                    duration: 0.3,
                                                                                }}
                                                                                className="px-1 py-2 text-gray-100 md:p-0 p5-regular"
                                                                            >
                                                                                {
                                                                                    items?.title
                                                                                }
                                                                            </motion.p>
                                                                        </Link>
                                                                    )
                                                                )}
                                                            </div>
                                                        </motion.section>
                                                    )}
                                            </AnimatePresence>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    <div
                        className={`md:border-t-0 md:border-b-[0.5px] border-gray-300 ${isLogged && !showMore
                            ? 'pb-0 md:pb-0 border-b-0 md:!border-b-0'
                            : 'py-5 md:py-6 border-y-[0.5px]'
                            } !my-0`}
                    >
                        <Text
                            content={'Help and Support'}
                            className="text-gray-200 p5-semibold"
                        />

                        <Text
                            content={
                                <>
                                    For any help related to your KYC,
                                    investments or account related support,
                                    please write to&nbsp;&nbsp;
                                    <a
                                        href={mailToGmail(supportEmail)}
                                        target="_blank"
                                        className="items-center p5-underline whitespace-nowrap"
                                    >
                                        <Image
                                            src="/images/link-down-arrow.svg"
                                            className="inline w-4 h-4 mr-1"
                                        />
                                        {supportEmail}
                                    </a>
                                </>
                            }
                            className="text-gray-200 p5-regular"
                        />
                    </div>

                    {/* Expandable details */}
                    <div className={` border-gray-300 border-b-[0.5px] pb-6`}>
                        <AnimatePresence initial={false}>
                            {(showMore || !isLogged) && (
                                <motion.section
                                    key="content"
                                    className="mt-4"
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: {
                                            opacity: 1,
                                            height: 'auto',
                                            y: 0,
                                        },
                                        collapsed: {
                                            opacity: 0,
                                            height: 0,
                                            y: '-10vh',
                                        },
                                    }}
                                    transition={{
                                        duration: 0.5,
                                        ease: 'easeIn',
                                    }}
                                >
                                    <>
                                        <Text
                                            content={
                                                'Disclaimer: Investments in debt securities/municipal debt securities/securitised debt instruments are subject to risks including delay and/ or default in payment. Read all the offer related documents carefully. '
                                            }
                                            className="text-gray-200 p5-regular"
                                        />
                                        <Text
                                            content={
                                                'Jiraaf is a brand under which Jiraaf Platform Private Limited offers its retail broking and investment services.'
                                            }
                                            className="mt-2 text-gray-200 p5-regular"
                                        />
                                        <div className="pt-4 border-gray-600 ">
                                            <Text
                                                content="Trading Member"
                                                className="text-gray-200 p5-semibold"
                                            />
                                            <div className="flex flex-col flex-wrap mt-2 text-gray-200 xl:items-center xl:flex-row p5-regular gap-y-2">
                                                <div className="flex flex-col md:items-center md:flex-row gap-y-2">
                                                    <Text
                                                        content={TRADING_NAME}
                                                        className="pr-4 mr-4 border-gray-400 md:border-r-1"
                                                    />
                                                    <Text
                                                        content={`CIN No. ${TRADING_CIN_NUMBER}`}
                                                        className="pr-4 mr-4 border-gray-400 xl:border-r-1"
                                                    />
                                                </div>
                                                <div className="flex flex-col md:items-center md:flex-row gap-y-2">
                                                    <Text
                                                        content={`SEBI Registration Number (Stock Broker): ${SEBI_REGISTRATION_NUMBER}`}
                                                        className="pr-4 mr-4 border-gray-400 md:border-r-1"
                                                    />
                                                    <Text
                                                        content={`NSE Member ID- Debt Segment: ${NSE_MEMBER_ID}`}
                                                        className="pr-4 mr-4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pt-4 border-gray-600 ">
                                            <Text
                                                content="Compliance Officer"
                                                className="text-gray-200 p5-semibold"
                                            />
                                            <div className="flex flex-col mt-2 text-gray-200 gap-y-2 md:flex-row md:items-center p5-regular">
                                                <Text
                                                    content={`${COMPLIANCE_OFFICER_NAME}`}
                                                    className="border-gray-400 md:pr-4 md:mr-4 md:border-r-1"
                                                />
                                                <div
                                                    className="flex items-center cursor-pointer"
                                                    onClick={() => {
                                                        window.location.href = `mailto:${COMPLIANCE_EMAIL}`;
                                                    }}
                                                >
                                                    <Text
                                                        content={
                                                            COMPLIANCE_EMAIL
                                                        }
                                                        className="border-gray-400 md:pr-4 md:mr-4 md:border-r-1"
                                                    />
                                                </div>
                                                <div
                                                    className="flex items-center cursor-pointer"
                                                    onClick={() => {
                                                        window.location.href = `tel:${COMPLIANCE_OFFICER_NUMBER}`;
                                                    }}
                                                >
                                                    <Text
                                                        content={
                                                            COMPLIANCE_OFFICER_NUMBER
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-between pt-4 border-gray-600 gap-y-3">
                                            <Text
                                                content={
                                                    <>
                                                        SEBI office addresses :{' '}
                                                        <Link
                                                            href={
                                                                SEBI_OFFICE_ADDRESS
                                                            }
                                                            target={'_blank'}
                                                            className={
                                                                'p5-underline'
                                                            }
                                                        >
                                                            {
                                                                SEBI_OFFICE_ADDRESS
                                                            }
                                                        </Link>
                                                    </>
                                                }
                                                isInnerHtml={false}
                                                className="text-gray-200 p5-regular"
                                            />
                                            <Text
                                                content={
                                                    <>
                                                        For any
                                                        complaints/grievance
                                                        pertaining to securities
                                                        broking please write to{' '}
                                                        <Link
                                                            href={`mailto:${GRIEVANCES_EMAIL}`}
                                                            target={'_blank'}
                                                            className={
                                                                'p5-underline'
                                                            }
                                                        >
                                                            {GRIEVANCES_EMAIL}
                                                        </Link>
                                                    </>
                                                }
                                                className="text-gray-200 p5-regular"
                                            />

                                            <Text
                                                content={
                                                    <>
                                                        Procedure to file a
                                                        complaint on SEBI
                                                        SCORES: Register on{' '}
                                                        <Link
                                                            href={`${SEBI_SCORE_LINK}`}
                                                            target={'_blank'}
                                                            className={
                                                                'p5-underline'
                                                            }
                                                        >
                                                            SCORES portal
                                                        </Link>
                                                        . Mandatory details for
                                                        filing complaints on
                                                        SCORES: Name, PAN,
                                                        Address, Mobile Number,
                                                        E-mail ID. Benefits:
                                                        Effective Communication,
                                                        Speedy redressal of the
                                                        grievances.
                                                    </>
                                                }
                                                className="text-gray-200 p5-regular"
                                            />

                                            <Text
                                                content={
                                                    <>
                                                        Procedure to initiate
                                                        dispute resolution
                                                        through SMART ODR
                                                        Portal: Register on{' '}
                                                        <Link
                                                            href={`${SMART_ODR_PORTAL}`}
                                                            target={'_blank'}
                                                            className={
                                                                'p5-underline'
                                                            }
                                                        >
                                                            SMART ODR Portal
                                                        </Link>
                                                        . File a New Dispute.
                                                        Benefits: After
                                                        exhausting above
                                                        mentioned options for
                                                        resolution of the
                                                        grievance (i.e. at the
                                                        Stock-Broker level and
                                                        escalation made to SEBI
                                                        SCORES), if the
                                                        investor/client is still
                                                        not satisfied with the
                                                        outcome, he/she/they can
                                                        initiate dispute
                                                        resolution through the
                                                        SMART ODR Portal.
                                                    </>
                                                }
                                                className="text-gray-200 p5-regular"
                                            />

                                            <Text
                                                content={
                                                    <>
                                                        As you are aware, under
                                                        the rapidly evolving
                                                        dynamics of financial
                                                        markets, it is crucial
                                                        for investors to remain
                                                        updated and
                                                        well-informed about
                                                        various aspects of
                                                        investing in securities
                                                        market. In this
                                                        connection, please find
                                                        a link to the SEBI
                                                        Investor Website{' '}
                                                        <Link
                                                            href={`${INVESTOR_SEBI_LINK}`}
                                                            target={'_blank'}
                                                            className={
                                                                'p5-underline'
                                                            }
                                                        >
                                                            {INVESTOR_SEBI_LINK}
                                                        </Link>{' '}
                                                        where you will find
                                                        useful information on
                                                        personal finance and
                                                        investment useful for
                                                        existing and new
                                                        investors. The website
                                                        contains videos prepared
                                                        by MIIs related to
                                                        securities market
                                                        process education and
                                                        awareness messages.
                                                    </>
                                                }
                                                className="text-gray-200 p5-regular"
                                            />
                                        </div>
                                    </>
                                </motion.section>
                            )}
                        </AnimatePresence>
                        {isLogged && (
                            <motion.header
                                initial={false}
                                animate={{}}
                                onClick={() => setShowMore(!showMore)}
                                className="mt-2"
                            >
                                <Text
                                    content={
                                        showMore ? 'Show less' : 'Show more'
                                    }
                                    className="text-gray-200 cursor-pointer p5-underline"
                                />
                            </motion.header>
                        )}
                    </div>

                    {/* Bottom */}
                    <div
                        className={`flex ${isLogged
                            ? 'gap-y-6 flex-col md:gap-x-4 items-center'
                            : 'gap-y-5 xl:gap-y-4 items-center xl:items-start flex-col xl:flex-row md:gap-x-4 xl:justify-between'
                            } w-full`}
                    >
                        <div class="flex flex-col w-full items-center xl:items-start">
                            <div className={`${isLogged ? 'xl:hidden' : ''}`}>
                                <Links />
                            </div>
                            <div
                                className={`flex items-center flex-col gap-y-3 xl:flex-row justify-center xl:justify-between xl:items-end w-full
                        `}
                            >
                                <div
                                    className={`flex flex-col items-center xl:items-start ${isLogged
                                        ? 'gap-2 mt-4'
                                        : 'gap-2 xl:gap-4 mt-4'
                                        }`}
                                >
                                    <Text
                                        className={`p5-regular text-gray-200 text-center`}
                                        content={`Designed, Developed & Maintained by AI Growth Private Limited.`}
                                    />
                                    <Text
                                        className={`p5-regular text-gray-200`}
                                        content={`Copyright © ${copyRightYear}, Jiraaf. All rights reserved.`}
                                    />
                                </div>
                                <div
                                    className={`${isLogged ? 'hidden xl:block' : 'hidden'
                                        }`}
                                >
                                    <Links />
                                </div>
                            </div>
                        </div>
                        {!isLogged ? (
                            <div className="block">
                                <Image
                                    class="w-20 h-20"
                                    src={'/images/iso-certificate.webp'}
                                    alt="iso icon"
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
