import { Text, Image } from '@atoms/index';

const FullPageWaitAnimation = ({ text }) => {
    return (
        <div className="download-progress fixed z-50 backdrop-filter backdrop-blur-xl h-screen top-0 left-0 right-0 insert-x-0 mx-auto">
            <div className="flex items-center justify-center h-screen">
                <div className="text-center">
                    <Image
                        className="w-1/2 mx-auto"
                        src="/images/admin/wait-animation.svg"
                    />
                    <Text size="lg" weight="bold" content={text} />
                </div>
            </div>
        </div>
    );
};

export default FullPageWaitAnimation;
